import React from 'react'

export function Error() {
  return (
    <div>
        <div class="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-inner text-center">
                            <h2 class="title">Pagina no encontrada</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="rbt-error-area rbt-section-gap pb--0 pt--60">
            <div className="error-area">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-10">
                            <div className="thumbnail">
                                <img src="assets/images/micrositio/PLearning_250_circulo_2.gif" alt="Image" />
                            </div>
                            <br></br>
                            <h3>Lo siento, pero la página que estás buscando no está disponible. </h3>
                            <a className="rbt-btn btn-gradient icon-hover" href="/">
                                <span className="btn-text">Ir al Inicio</span>
                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Error