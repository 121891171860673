import React from 'react';

function SideNotification({ onClose }) {
    return (
        <div className="rbt-cart-side-menu side-menu-active d-none">
        <div className="inner-wrapper">
            <div className="inner-top">
                <div className="content">
                    <div className="title">
                    <h4 className="title mb--0">Ultimas notificaciones</h4>
                    </div>
                    <div className="rbt-btn-close" id="btn_sideNavClose">
                    <button className="minicart-close-button rbt-round-btn" onClick={onClose}>
                        <i className="feather-x"></i>
                    </button>
                    </div>
                </div>
            </div>
            <nav className="side-nav w-100">
                <ul className="rbt-minicart-wrapper">
                    <li className="minicart-item">
                        <div className="product-content">
                            <h6 className="title">
                            <a href="#"><i class="feather-award mr--10" style={{color:'#074975'}}></i>¡Eres el mejor participante de tu compañía!</a>
                            </h6>
                            <span className="quantity">
                            Tu premio i Love Panduit ya está en camino a la dirección proporcionada. Nuestros envíos toman de 9 a 11 días hábiles, debido los proceso de empaque y mensajería. 
                            </span>
                        </div>
                        <div className="close-btn">
                            <button className="rbt-round-btn" onClick={onClose}>
                            <i className="feather-x"></i>
                            </button>
                        </div>
                    </li>
                    <li className="minicart-item">
                        <div className="product-content">
                            <h6 className="title">
                            <a href="#"><i class="feather-package mr--10" style={{color:'#074975'}}></i>El premio que seleccionaste ya está en camino</a>
                            </h6>
                            <span className="quantity">
                            Tomaremos la dirección que nos diste y lo haremos llegar lo más pronto posible. Recuerda que nuestros envíos toman de 9 a 11 días hábiles, debido a los procesos de empaquetado y mensajería. Si embargo, por la temporada podrian tarar un poco más. 
                            </span>
                        </div>
                        <div className="close-btn">
                            <button className="rbt-round-btn" onClick={onClose}>
                            <i className="feather-x"></i>
                            </button>
                        </div>
                    </li>
                    <li className="minicart-item">
                        <div className="product-content">
                            <h6 className="title">
                            <a href="#"><i class="feather-check-circle mr--10" style={{color:'#074975'}}></i>¡Has aceptado el desafío!</a>
                            </h6>
                            <span className="quantity">
                            Tu registro fue aceptado: comienza a vender los productos participantes para subir de nivel y conseguir los bonos. No olvides que debes registrar tus facturas y órdenes de compra en el sitio de PLearning para acreditar tus ventas. ¡Te deseamos el mayor de los éxitos!
                            </span>
                        </div>
                        <div className="close-btn">
                            <button className="rbt-round-btn" onClick={onClose}>
                            <i className="feather-x"></i>
                            </button>
                        </div>
                    </li>
                    <li className="minicart-item">
                        <div className="product-content">
                            <h6 className="title">
                            <a href="#"><i class="feather-message-circle mr--10" style={{color:'#074975'}}></i>Mensaje en sesión</a>
                            </h6>
                            <span className="quantity">
                            Un experto a respondido a tu pregunta, observala en la caja de preguntas.
                            </span>
                        </div>
                        <div className="close-btn">
                            <button className="rbt-round-btn" onClick={onClose}>
                            <i className="feather-x"></i>
                            </button>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
        </div>
    );
}

export default SideNotification;
