
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory  } from 'react-router-dom';

import {Header, Footer} from './widgets/Widgets';

import Home from './secciones/Home';
import {Temporada, TemporadaEspecifica, Sesion, OtrasSesiones, Trivia, TriviaDireccion, Jackpot, Check10, Incentivos} from './secciones/Actividades';
import {ListaPaginas, Pagina, PreguntasFrecuentes, Reglamento, PaginaTyC, AvisoPrivacidad} from './secciones/Paginas';
import {ListaDeLogros, Logro, Champions, DetalleChampios} from './secciones/Logros';
import {HomeCanje, Catalogo, Recompensa, RecompensaNew, RecompensaProduct, Checkout, CheckoutDetail, CheckoutOrderDetail} from './secciones/Canje';
import {Resumen, ResumenSuper, Distribuidores, Top10Lider, UsuariosLider, UsuariosSuperLider, UsuarioLider, SolicitudesLogros} from './secciones/Lideres';
import {Login, OlvidePass, RestaurarPass, Gate, CambiarPrograma} from './secciones/Login';
import {Perfil, MockupPerfil} from './secciones/Usuario';
import Error from './secciones/Error';
import { GlobalProvider } from './globales/VariablesGlobales';
import axios from 'axios';


function App() {
  return (
    <GlobalProvider>
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <PrivateRoute path="/temporadas"><Temporada/></PrivateRoute>
          <PrivateRoute path="/temporada/:id_temporada"><TemporadaEspecifica/></PrivateRoute>
          <PrivateRoute path="/sesion/:id"><Sesion/></PrivateRoute>
          <PrivateRoute path="/ilovepanduit/direccion"><TriviaDireccion/></PrivateRoute> 
          <PrivateRoute path="/ilovepanduit"><Trivia/></PrivateRoute> 
          <PrivateRoute path="/jackpot"><Jackpot/></PrivateRoute> 
          <PrivateRoute path="/top-10/:region?"><Check10/></PrivateRoute> 
          <PrivateRoute path="/incentivos"><Incentivos/></PrivateRoute>
          <PrivateRoute path="/otras-sesiones"><OtrasSesiones/></PrivateRoute>
          
          <Route path="/paginas" exact component={ListaPaginas} />
          <Route path="/pagina/:id" exact component={Pagina} />
          <Route path="/preguntas-frecuentes" exact component={PreguntasFrecuentes} />
          <Route path="/reglamento" exact component={Reglamento} />
          <Route path="/TyC" exact component={PaginaTyC} />
          <Route path="/aviso-privacidad" exact component={AvisoPrivacidad} />

          <Route path="/champions" exact component={Champions} />
          <Route path="/detalle-champions/:id" exact component={DetalleChampios} />
          <Route path="/logros" exact component={ListaDeLogros} />
          <Route path="/logro" exact component={Logro} />

          <Route path="/canjeo-de-puntos" exact component={HomeCanje} />
          <Route path="/catalogo" exact component={Catalogo} />
          <Route path="/recompensas" exact component={RecompensaNew} />
          <Route path="/recompensas/producto/:id" exact component={RecompensaProduct} />
          <Route path="/checkout" exact component={Checkout} />
          <Route path="/recompensas/detalle/:id" exact component={CheckoutDetail} />
          <Route path="/recompensas/pedidos" exact component={CheckoutOrderDetail} />


          <PrivateRoute path="/lider" exact><Resumen /></PrivateRoute>
          <PrivateRoute path="/lider/top-10"><Top10Lider /></PrivateRoute>
          <PrivateRoute path="/lider/usuarios"><UsuariosLider /></PrivateRoute>
          <PrivateRoute path="/lider/usuario"><UsuarioLider /></PrivateRoute>
          <PrivateRoute path="/lider/logros"><SolicitudesLogros /></PrivateRoute>

          <PrivateRoute path="/superlider/:region?" exact><Distribuidores /></PrivateRoute>
          <PrivateRoute path="/superlider/resumen/:id_distribuidor" exact><ResumenSuper /></PrivateRoute>
          <PrivateRoute path="/superlider/usuarios/:id_distribuidor"><UsuariosSuperLider /></PrivateRoute>

          <Route path="/login" exact component={Login} />
          <Route path="/login/olvide" exact component={OlvidePass} />
          <Route path="/login/restaurar/:id/:di" exact component={RestaurarPass} />
          <Route path="/acceso/:programa" exact component={Gate} />
          <Route path="/cambiar_programa/:id/:token/:distribuidor/:region" exact component={CambiarPrograma} />
          <Route path="/usuario/perfil" exact component={Perfil} />
          {/* <Route path="/mockup-perfil" exact component={MockupPerfil} /> */}

          <Route path="*" exact component={Error} />
          
        </Switch>
        <Footer />
      </Router>
    </GlobalProvider>
  );
}

// Esta función protege las rutas privadas
function PrivateRoute({ children, ...rest }) {
  let token = localStorage.getItem('authLaravel');
  let auth = false;
  if(token !== null && token !== undefined && token !== '') {
    auth = true;
    console.log('autentificado');
  }
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}


export default App;
