import React, { createContext, useContext, useState, useEffect} from 'react';
import axios from 'axios';

const VariablesGlobales = createContext();

export const useVariablesGlobales = () => useContext(VariablesGlobales);

export const GlobalProvider = ({ children }) => {
  const [cuentaPL, setCuentaPL] = useState('1');
  const [temporadaDefault, setTemporadaDefault] = useState('1');
  const [authTokenExist, setAuthTokenExist] = useState(false);
  const token = localStorage.getItem('authLaravel');
  const distribuidor = JSON.parse(localStorage.getItem('Distribuidor'));
  const region = JSON.parse(localStorage.getItem('Region'));
  const [authToken, setAuthToken] = useState(token);
  const [nombreDistribuidor, setNombreDistribuidor] = useState(distribuidor);
  const [nombreRegion, setNombreRegion] = useState(region);

  useEffect(() => {
    if (token !== null && token !== undefined && token !== '') {
      setAuthTokenExist(true);
    }
  }, []);

  //console.log('desde globales:'+authTokenExist);

  // Objeto que contiene todas las variables de estado
  const globalState = {
    cuentaPL,
    setCuentaPL,
    authTokenExist,
    setAuthTokenExist,
    authToken,
    setAuthToken,
    nombreDistribuidor,
    setNombreDistribuidor,
    nombreRegion,
    setNombreRegion
  };

  return (
    <VariablesGlobales.Provider value={globalState}>
      {children}
    </VariablesGlobales.Provider>
  );
};