import React from 'react';

const ToastNotification = ({ show, onClose, message = "Hola, este es un mensaje" }) => {
    return (
        <div className='toast-container position-relative'>
            <div class="bs-toast toast fade show toast-ex d-none" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <i class="feather-award" style={{color:'#074975'}}></i>
                    <span class="me-auto ml--10">¡Has aceptado el desafío!</span>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={onClose}></button>
                </div>
                <div class="toast-body">¡Registro aceptado! Comienza a vender, registra tus facturas y órdenes en PLearning y sube de nivel para ganar bonos. ¡Éxito!</div>
            </div>

            <div class="bs-toast toast fade show toast-ex d-none " role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <i class="feather-message-circle" style={{color:'#074975'}}></i>
                    <span class="me-auto ml--10">Mensaje en sesión</span>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={onClose}></button>
                </div>
                <div class="toast-body">Un experto a respondido a tu pregunta, observala en la caja de preguntas.</div>
            </div>

            <div class="bs-toast toast fade show toast-ex d-none" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <i class="feather-package" style={{color:'#074975'}}></i>
                    <span class="me-auto ml--10">El premio seleccionado está en camino</span>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={onClose}></button>
                </div>
                <div class="toast-body">Usaremos la dirección proporcionada y enviaremos tu pedido lo antes posible. <br></br> El envío tomará entre 9 y 11 días hábiles, aunque podría tardar un poco más por la temporada.</div>
            </div>

            <div class="bs-toast toast fade show toast-ex d-none" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <i class="feather-award" style={{color:'#93c47d'}}></i>
                    <span class="me-auto ml--10">¡Felicidades!</span>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={onClose}></button>
                </div>
                <div class="toast-body">Tu evidencia fue validada y cumpliste los requisitos del nivel. ¡Sigue participando para desbloquear todos los bonos!</div>
            </div>
        </div>
        
    );
};

export default ToastNotification;
