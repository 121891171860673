import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { SeccionUno, SeccionDos, MultiSteps1 } from "../components/sections";
import { Button, Modal, Table } from 'react-bootstrap';
import {   BrowserRouter as Router,
    useHistory, useParams } from 'react-router-dom';
import { useVariablesGlobales } from '../globales/VariablesGlobales';
import axios from 'axios';

import {Activos, Participantes} from '../components/DoughnutCharts';
import PolarAreaChart from '../components/PolarAreaChart';
import LinesChart from '../components/LineCharts';
import { UsuariosParticipantes, UsuariosActivos, PanelLider } from '../components/EChartsBarChart';

axios.defaults.withCredentials = false;
axios.defaults.withXSRFToken = true;


export function Resumen() {

    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    // Cargo variables del servidor
    const [datosLider, setDatosLider] = useState(null);
    const [datosTemporada, setDatosTemporada] = useState(null);
    const [datosSuscripcion, setDatosSuscripcion] = useState(null);
    const [datosDistribuidor, setDatosDistribuidor] = useState(null);
    const [listadoUsuarios, setListadoUsuarios] = useState(null);
    const [usuariosTotales, setUsuariosTotales] = useState(null);
    const [usuariosActivos, setUsuariosActivos] = useState(null);
    const [usuariosParticipantes, setUsuariosParticipantes] = useState(null);
    const [usuariosInactivos, setUsuariosInactivos] = useState(null);
    const [totalSesiones, setTotalSesiones] = useState(null);
    const [totalTrivias, setTotalTrivias] = useState(null);
    const [totalJackpots, setTotalJackpots] = useState(null);
    const [pendientesSesiones, setPendientesSesiones] = useState(null);
    const [pendientesTrivias, setPendientesTrivias] = useState(null);
    const [pendientesJackpots, setPendientesJackpots] = useState(null);
    const [porcentajeActivos, setPorcentajeActivos] = useState(0);
    const [porcentajeParticipantes, setPorcentajeParticipantes] = useState(0);

    const [arrayNombres, setArrayNombres] = useState(null);
    const [arrayNombresSesiones, setArrayNombresSesiones] = useState(null);
    const [arrayNombresTrivias, setArrayNombresTrivias] = useState(null);
    const [topSesiones, setTopSesiones] = useState(null);
    const [topTrivias, setTopTrivias] = useState(null);
    const [topJackpots, setTopJackpots] = useState(null);
    const [numeroUsuariosSesiones, setNumeroUsuariosSesiones] = useState(null);
    const [numeroUsuariosTrivias, setNumeroUsuariosTrivias] = useState(null);
    const [numeroUsuariosJackpots, setNumeroUsuariosJackpots] = useState(null);
    const [top10, setTop10] = useState(null);
    const [participacionesSesiones, setParticipacionesSesiones] = useState(null);
    const [participacionesTrivias, setParticipacionesTrivias] = useState(null);
    const [participacionesJackpots, setParticipacionesJackpots] = useState(null);

    const porcentajeConDosDecimales = porcentajeActivos.toFixed(2);


    const[arrayFechas, setArrayFechas] = useState(null);
    const[arrayVisualizaciones, setArrayVisualizaciones] = useState(null);
    const[arrayEvaluaciones, setArrayEvaluaciones] = useState(null);
    const[arrayTrivias, setArrayTrivias] = useState(null);
    const[arrayJackpots, setArrayJackpots] = useState(null);

   

    

      // Termina el Auth redirect
    useEffect(() => {
    // llamada para los datos de la temporada
    const id_local = JSON.parse(localStorage.getItem('idUsuario'));
    axios.get('https://plsystem.quarkservers2.com/api/datos_basicos_lider', {
        params: {
            id_cuenta: cuentaPL,
            id_usuario: id_local
        }
        })
        .then(function (response) {
        setDatosLider(response.data.usuario);
        setDatosSuscripcion(response.data.suscripcion);
        setDatosTemporada(response.data.temporada);
        setDatosDistribuidor(response.data.distribuidor);
        setListadoUsuarios(response.data.suscriptores);
        setUsuariosTotales(response.data.totales);
        setUsuariosActivos(response.data.activos);
        setUsuariosParticipantes(response.data.participantes);
        let usuarios_inactivos = parseInt(response.data.totales) - parseInt(response.data.activos);
        let porcentaje_activos = (parseInt(response.data.activos)*100)/parseInt(response.data.totales);
        let porcentaje_participantes = (parseInt(response.data.participantes)*100)/parseInt(response.data.totales);
        let porcentaje_activos_decimal = Math.round(porcentaje_activos * 100) / 100;
        let porcentaje_participantes_decimal = Math.round(porcentaje_participantes * 100) / 100;
        
        setUsuariosInactivos(usuarios_inactivos);
        
        setTotalSesiones(response.data.sesiones);
        setTotalTrivias(response.data.trivias);
        setTotalJackpots(response.data.jackpots);
        setPendientesSesiones(response.data.sesiones_pendientes);
        setPendientesTrivias(response.data.trivias_pendientes);
        setPendientesJackpots(response.data.jackpots_pendientes);
       
        setPorcentajeActivos(porcentaje_activos_decimal);
        setPorcentajeParticipantes(porcentaje_participantes_decimal);
        setTopSesiones(response.data.top_sesiones);
        setTopTrivias(response.data.top_trivias);
        setTopJackpots(response.data.top_jackpots);
        setTop10(response.data.top_10);
        setNumeroUsuariosSesiones(response.data.no_usuarios_sesiones);
        setNumeroUsuariosTrivias(response.data.no_usuarios_trivias);
        setNumeroUsuariosJackpots(response.data.no_usuarios_jackpots);
        setParticipacionesSesiones(response.data.participaciones_sesiones);
        setParticipacionesTrivias(response.data.participaciones_trivias);
        setParticipacionesJackpots(response.data.participaciones_jackpots);


        setArrayNombres(response.data.array_nombres);
        setArrayNombresSesiones(response.data.array_nombres_sesiones);
        setArrayNombresTrivias(response.data.array_nombres_trivias);

        setArrayFechas(response.data.array_fechas);
        setArrayVisualizaciones(response.data.engagement_visualizaciones);
        setArrayEvaluaciones(response.data.engagement_evaluaciones);
        setArrayTrivias(response.data.engagement_trivias);
        setArrayJackpots(response.data.engagement_jackpots);
        
        })
        .catch(function (error) {
        // handle error
        console.log('El error fue:'+error);
        });

    }, []);
    

    const obtenerFechaActual = () => {
        const fecha = new Date();
        const year = fecha.getFullYear();
        let month = fecha.getMonth() + 1;
        let day = fecha.getDate();

        // Ajustar el formato de mes y día para que tengan dos dígitos si es necesario
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }

        // Formatear la fecha en el formato YYYY-MM-DD requerido por el campo de fecha
        return `${year}-${month}-${day}`;
    };

    const obtenerFechaAtras = () => {
        const fecha = new Date();
        fecha.setDate(fecha.getDate() - 15); // Restar 7 días a la fecha actual
    
        const year = fecha.getFullYear();
        let month = fecha.getMonth() + 1;
        let day = fecha.getDate();
    
        // Ajustar el formato de mes y día para que tengan dos dígitos si es necesario
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }
    
        // Formatear la fecha en el formato YYYY-MM-DD requerido por el campo de fecha
        return `${year}-${month}-${day}`;
    };

    const actualizarPanel = (e) => {
        e.preventDefault();

        // llamada para los datos de la temporada
    const id_local = JSON.parse(localStorage.getItem('idUsuario'));
    axios.get('https://plsystem.quarkservers2.com/api/panel_lider', {
        params: {
            id_cuenta: cuentaPL,
            id_usuario: id_local,
            fecha_inicio: fechaDesde,
            fecha_final: fechaHasta
        }
        })
        .then(function (response) {
        console.log(response);
        setArrayFechas(response.data.fechas_array);
        setArrayVisualizaciones(response.data.engagement_visualizaciones);
        setArrayEvaluaciones(response.data.engagement_evaluaciones);
        setArrayTrivias(response.data.engagement_trivias);
        setArrayJackpots(response.data.engagement_jackpots);
        
        })
        .catch(function (error) {
        // handle error
        console.log('El error fue:'+error);
        });

    }

    // Establecer el estado inicial de los campos de fecha con la fecha actual
    const [fechaDesde, setFechaDesde] = useState(obtenerFechaAtras());
    const [fechaHasta, setFechaHasta] = useState(obtenerFechaActual());

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if(datosLider){
        return (
            <div>
                <div className="rbt-breadcrumb-default ptb--50 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-left">
                                    <h3 className="title">Panel Líder <span className="color-third">{datosDistribuidor.nombre}</span> </h3> <br/>
                                </div>
                                <div className="rbt-author-meta mb--10">
                                    <div className="rbt-avater">
                                        <a href="#"><img src={`https://plsystem.quarkservers2.com/img/usuarios/${datosLider.imagen}`} alt={datosLider.nombre}/></a>
                                    </div>
                                        <div className="rbt-author-info text-white">
                                            {datosLider.nombre} {datosLider.apellidos}
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                
        
              <div className="rbt-counterup-area rbt-section-overlayping-top rbt-section-gapBottom pt--150 pb--75">
                
                <div className="section-title text-center mb--40 mt--60">
                    <p className="description has-medium-font-size mt--20 mb--0">En este espacio exclusivo podrás visualizar información relevante sobre el desempeño de usuarios de tu compañía en nuestro programa.</p>
                    <div className="rbt-button-group mt--30">
                        <a className="rbt-btn btn-gradient hover-icon-reverse" href="/lider/usuarios">
                            <span className="icon-reverse-wrapper">
                                <span className="btn-text">Agregar/Borrar usuario</span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            </span>
                        </a>
                        <a className="rbt-btn hover-icon-reverse btn-border" href="/usuario/perfil">
                            <span className="icon-reverse-wrapper">
                                <span className="btn-text">Salir</span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            </span>
                        </a>
                    </div>
                </div>
               
                <div className="container">
                    <div className="row g-5">
                        <div className="section-title text-center">
                            <h2 className="title">Desempeño de usuarios</h2>
                            <h4 className="title" style={{color:'var(--color-body)'}}>Usuarios: {usuariosTotales} Registrados (total de usuarios)</h4>
                        </div>
                        {/* Start Single Event  */}
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="rbt-card variation-01 rbt-hover">
                            <h5 className="rbt-card-title center">Usuarios activos</h5>
                            <hr/>
                                <div className="rbt-card-img">
                                    <UsuariosActivos activos={usuariosActivos} no_activos={usuariosInactivos} />
                                    
                                </div>
                                <div className="rbt-card-body">
                                    <table style={{ width: '100%', textAlign: 'left', color: '#000', borderCollapse: 'collapse' }}>
                                        <tr>
                                            <td style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ display: 'block', width: '10px', height: '10px', margin: '10px', backgroundColor: '#36a2eb' }}></span>
                                                    <b style={{ margin: '5px' }}>{usuariosActivos}</b> Usuarios activos: ({porcentajeActivos.toFixed(2)}%)
                                                </div>
                                            </td>
                                            <td style={{ borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>Han iniciado sesión</td>
                                        </tr>
                                        <tr>
                                            <td style={{ verticalAlign: 'middle', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ display: 'block', width: '10px', height: '10px', margin: '10px', backgroundColor: '#ff6384' }}></span>
                                                    <b style={{ margin: '5px' }}>{usuariosInactivos}</b> Usuarios inactivos: ({(100 - porcentajeActivos).toFixed(2)}%)
                                                </div>
                                            </td>
                                            <td style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>Nunca han iniciado sesión</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* End Single Event  */}
                        {/* Start Single Event  */}
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="rbt-card variation-01 rbt-hover">
                            <h5 className="rbt-card-title center">Usuarios participantes</h5>
                            <hr/>
                                <div className="rbt-card-img">
                                <UsuariosParticipantes porcentaje={porcentajeParticipantes}/>
                                </div>
                                <div className="rbt-card-body">
                                <table style={{ width: '100%', textAlign: 'left', color: '#000', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ display: 'block', width: '10px', height: '10px', margin: '10px', backgroundColor: '#36a2eb' }}></span>
                                                    <b style={{ margin: '5px' }}>{usuariosParticipantes}</b> Usuarios ({porcentajeParticipantes.toFixed(2)}%)
                                                </div>
                                            </td>
                                            <td style={{ borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>Han participado en alguna actividad</td>
                                        </tr>
                                        <tr>
                                            <td style={{ verticalAlign: 'middle', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ display: 'block', width: '10px', height: '10px', margin: '10px', backgroundColor: '#ff6384' }}></span>
                                                    <b style={{ margin: '5px' }}>{usuariosTotales - usuariosParticipantes}</b> Usuarios ({(100 - porcentajeParticipantes).toFixed(2)}%)
                                                </div>
                                            </td>
                                            <td style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>No han participado en ninguna actividad</td>
                                        </tr>
                                    </tbody>
                                </table>

                                </div>
                            </div>
                        </div>
                        {/* End Single Event  */}
                    </div>
                </div>
              </div>
        
                <div className="rbt-service-area rbt-section-gap bg-color-extra2 pt--0 pb--0">
                    <div className="container">
                        {/*  Start Card Area */}
                        <div className="row row--15 mt_dec--30">
        
                            {/*  Start Service Grid  */}
                            <div className="col-lg-12 col-xl-12 col-xxl-12 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner pt--15 pb--15">
                                        <div className="row">
                                            <div className="col">
                                            <form className="row justify-content-end">
                                                <input type="hidden" name="id_distribuidor" value="" />
                                                <div className="col-4">
                                                    <div className="form-group d-flex">
                                                        <input
                                                            type="date"
                                                            name="fecha_desde"
                                                            className="form-control"
                                                            value={fechaDesde}
                                                            onChange={(e) => setFechaDesde(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group d-flex">
                                                        <input
                                                            type="date"
                                                            name="fecha_hasta"
                                                            className="form-control"
                                                            value={fechaHasta}
                                                            onChange={(e) => setFechaHasta(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            <div className="col-4">
                                                <a className="rbt-btn hover-icon-reverse btn-sm mr--20 ml--40" onClick={actualizarPanel} href="#" >Filtrar</a>
                                                <a className="rbt-btn hover-icon-reverse btn-sm" style={{ background: '#f0b323', cursor:'pointer' }} onClick={handleShow}>Descargar reportes</a>

                                                <Modal show={show} onHide={handleClose} style={{ borderRadius: '50px' }} centered size="lg">
                                                    <Modal.Header closeButton style={{ fontSize: '12px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                                                        <span style={{ fontSize: '22px', fontWeight: '700', color: '#000' }}>Reportes</span>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <p className="text-center" style={{ fontWeight: '700' }}>Selecciona una opción para descargar una hoja de cálculo con la información</p>
                                                        <Table style={{ width: '100%', textAlign: 'left', color: '#000',  }}>
                                                            <tbody style={{ border: 'none' }}>
                                                                <tr>
                                                                    <td style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none', borderBottom:'none' }}><h5>Usuarios</h5><p>En este compilado podrás ver la lista completa de usuarios registrados en el programa.</p></td>
                                                                    <td style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none', borderBottom:'none' }}><h5>Puntos</h5><p>En este compilado podrás ver la participación en actividades y el puntaje total de los usuarios.</p></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-center" style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' , borderBottom:'none' }}>
                                                                        <a className="rbt-btn hover-icon-reverse btn-sm" href={`https://plsystem.quarkservers2.com/admin/usuarios_suscritos/reporte?id_temporada=${datosTemporada.id}&id_distribuidor=${datosDistribuidor.id}`}  style={{ margin: '0 auto' }}>Descargar</a></td>
                                                                    <td className="text-center" style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' , borderBottom:'none' }}>
                                                                        <a className="rbt-btn hover-icon-reverse btn-sm" href={`https://plsystem.quarkservers2.com/admin/usuarios_suscritos/puntaje?id_temporada=${datosTemporada.id}&id_distribuidor=${datosDistribuidor.id}`}  style={{ background: '#f0b323', margin: '0 auto' }}>Descargar</a></td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Modal.Body>
                                                </Modal>
                                            </div>
                                        </form>
                                            </div>
                                        </div>
                                        <div className="alert alert-warning text-center" ><p style={{fontSize:'12px', marginBottom:'5px'}}><b>Algunas fechas de lanzamiento podrían no aparecer en las acotaciones de la gráfica. </b>
                                         Para visualizar las fechas de los lanzamientos con mayor detalle, seleccione un periodo no mayor a 30 días.</p>
                                        </div>
                                        
                                        <PanelLider 
                                            fechas={arrayFechas}
                                            visualizaciones={arrayVisualizaciones}
                                            evaluaciones={arrayEvaluaciones}
                                            trivias={arrayTrivias}
                                            jackpots={arrayJackpots} />
                                        
        
                                        <div className="rbt-service-area rbt-section-gap pb--60 pt--60 ">
                                            <div className="container">
                                                <div className="row mb--60">
                                                    <div className="col-lg-12">
                                                        <div className="section-title text-center">
                                                            <h2 className="title">Actividades Completadas</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Start Card Area */}
                                                <div className="row row--15 mt_dec--30">
                                                    {/* Start Single Card  */}
                                                    <div className="col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                                                        <div className="rbt-card text-center">
                                                            <img src="/assets/images/micrositio/PLe_Liderico01_2024.png" alt="card-icon" />
                                                            <h5 className="title"><a href="#">Sesiones</a></h5>
                                                            <table style={{ borderCollapse: 'collapse', textAlign: 'left' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Sesiones</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{totalSesiones - pendientesSesiones}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Usuarios</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{numeroUsuariosSesiones}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Participaciones</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{participacionesSesiones}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>


                                                        </div>
                                                    </div>
                                                    {/* End Single Card  */}
        
                                                    {/* Start Single Card  */}
                                                    <div className="col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                                                        <div className="rbt-card text-center">
                                                            <img src="/assets/images/micrositio/PLe_Liderico02_2024.png" alt="card-icon" />
                                                            <h5 className="title"><a href="#">Trivias</a></h5>
                                                            <table style={{ borderCollapse: 'collapse', textAlign: 'left' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Trivias</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{totalTrivias - pendientesTrivias}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Usuarios</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{numeroUsuariosTrivias}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Participaciones</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{participacionesTrivias}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                    {/* End Single Card  */}
        
                                                    {/* Start Single Card  */}
                                                    <div className="col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                                                        <div className="rbt-card text-center">
                                                            <img src="/assets/images/micrositio/PLe_Liderico03_2024.png" alt="card-icon" />
                                                            <h5 className="title"><a href="#">Jackpots</a></h5>
                                                            <table style={{ borderCollapse: 'collapse', textAlign: 'left' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Jackpots</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{totalJackpots - pendientesJackpots}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Usuarios</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{numeroUsuariosJackpots}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Ganadores</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{participacionesJackpots}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                    {/* End Single Card  */}
        
                                                    {/* Start Single Card  */}
                                                    <div className="col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                                                    <div className="rbt-card text-center">
                                                        <img src="/assets/images/micrositio/PLe_Liderico04_2024.png" alt="card-icon" />
                                                            <h5 className="title"><a href="#">Engagement</a></h5>
                                                            <table style={{ borderCollapse: 'collapse', textAlign: 'left' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Jackpots</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{totalJackpots - pendientesJackpots}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Usuarios</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{numeroUsuariosJackpots}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Ganadores</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{participacionesJackpots}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                    {/* End Single Card  */}
        
                                                </div>
                                                {/* End Card Area */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*  End Service Grid  */}
        
                        </div>
                        {/*  End Card Area */}
                    </div>
                </div>
        
                <div className="rbt-advance-tab-area rbt-section-gap pt--30">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="title">Top 10</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="advance-tab-button">
                                    <ul className="nav nav-tabs tab-button-style-2 mb--0" id="myTab-4" role="tablist">
                                        <li role="presentation">
                                            <a href="#" className="tab-button active" id="home-tab-4" data-bs-toggle="tab" data-bs-target="#home-4" role="tab" aria-controls="home" aria-selected="true">
                                                <span className="title">Top sesiones</span>
                                            </a>
                                        </li>
                                        <li role="presentation">
                                            <a href="#" className="tab-button" id="profile-tab-4" data-bs-toggle="tab" data-bs-target="#profile-4" role="tab" aria-controls="profile-4" aria-selected="false">
                                                <span className="title">Top trivias</span>
                                            </a>
                                        </li>
                                        <li role="presentation">
                                            <a href="#" className="tab-button" id="contact-tab-4" data-bs-toggle="tab" data-bs-target="#contact-4" role="tab" aria-controls="contact-4" aria-selected="false">
                                                <span className="title">Top 10 y Puntaje</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-10 offset-lg-1 mt--10">
                                <div className="tab-content advance-tab-content-style-2 pl--0 pr--0">
                                    <div className="tab-pane fade active show" id="home-4" role="tabpanel" aria-labelledby="home-tab-4">
                                        <div className="content">
                                            <div className="col-lg-12 col-md-4 col-12">
                                                <div className="rbt-card variation-01 rbt-hover">
                                                <hr/>
                                                    <div className="rbt-card-img">
                                                        <div className="table-responsive mobile-table-750">
                                                            <table className="rbt-table table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th>LUGAR</th>
                                                                        <th>SESION</th>
                                                                        <th>PARTICIPANTES</th>
                                                                    </tr>
                                                                </thead>
                                                                
                                                                <tbody>
                                                                    {topSesiones.map((sesion, index) => (
                                                                        
                                                                        <tr  key={sesion.id}>
                                                                            <th>{index+1}</th>
                                                                            <td>{arrayNombresSesiones[sesion.id]}</td>
                                                                            <td>{sesion.puntos}</td>
                                                                        </tr>
                                                                    
                                                                    ))}
                                                                </tbody>
                                                                
                                                                
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="profile-4" role="tabpanel" aria-labelledby="profile-tab-4">
                                        <div className="content">
                                            <div className="col-lg-12 col-md-4 col-12">
                                                <div className="rbt-card variation-01 rbt-hover">
                                                <hr/>
                                                    <div className="rbt-card-img">
                                                        <div className="table-responsive mobile-table-750">
                                                            <table className="rbt-table table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th>LUGAR</th>
                                                                        <th>TRIVIA</th>
                                                                        <th>PARTICIPANTES</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {topTrivias.map((trivia, index) => (
                                                                        
                                                                        <tr  key={trivia.id}>
                                                                            <th>{index+1}</th>
                                                                            <td>{arrayNombresTrivias[trivia.id]}</td>
                                                                            <td>{trivia.puntos}</td>
                                                                        </tr>
                                                                    
                                                                    ))}
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="contact-4" role="tabpanel" aria-labelledby="contact-tab-4">
                                        <div className="content">
                                            <div className="col-lg-12 col-md-4 col-12">
                                                <div className="rbt-card variation-01 rbt-hover">
                                                <hr/>
                                                    <div className="rbt-card-img">
                                                        <div className="table-responsive mobile-table-750">
                                                            <table className="rbt-table table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th>LUGAR</th>
                                                                        <th>NOMBRE</th>
                                                                        <th>COMPAÑIA</th>
                                                                        <th>PUNTOS</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {top10.slice(0, 10).map((usuario, index) => (
                                                                        
                                                                    <tr  key={usuario.id}>
                                                                        <th>{index+1}</th>
                                                                        <td>{arrayNombres[usuario.id]}</td>
                                                                        <td>{datosDistribuidor.nombre}</td>
                                                                        <td>{usuario.puntos}</td>
                                                                    </tr>
                                                                    
                                                                ))}
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
    }
  }
  export function Distribuidores() {

    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    const { region } = useParams();
    // Cargo variables del servidor
    const [datosLider, setDatosLider] = useState(null);
    const [datosSuscripcion, setDatosSuscripcion] = useState(null);
    const [regionServidor, setRegionServidor] = useState(null);
    const [datosTemporada, setDatosTemporada] = useState(null);
    const [listaDistribuidores, setListaDistribuidores] = useState(null);
    const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const params = {
        id_cuenta: cuentaPL,
        id_usuario: id_local,
        region: region, // Puedes omitir esto si no quieres enviar 'region' cuando es undefined
      };

      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([_, v]) => v != null)
      );

      // Termina el Auth redirect
    useEffect(() => {
    // llamada para los datos de la temporada
    console.log();
    axios.get('https://plsystem.quarkservers2.com/api/distribuidores_super_lider', {
        params: filteredParams
        })
        .then(function (response) {
            setDatosLider(response.data.usuario);
            setDatosTemporada(response.data.temporada);
            setDatosSuscripcion(response.data.suscripcion);
            setListaDistribuidores(response.data.distribuidores);
            setRegionServidor(response.data.region);
        })
        .catch(function (error) {
        // handle error
        console.log('El error fue:'+error);
        });

    }, []);


    if(listaDistribuidores){
        return (
            <div>
                <div className="rbt-breadcrumb-default ptb--50 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-left">
                                    <h3 className="title">Panel Super Líder <span className="color-third">{regionServidor}</span> </h3> <br/>
                                </div>
                                <div className="rbt-author-meta mb--10">
                                    <div className="rbt-avater">
                                        <a href="#"><img src={`https://plsystem.quarkservers2.com/img/usuarios/${datosLider.imagen}`} alt={datosLider.nombre}/></a>
                                    </div>
                                        <div className="rbt-author-info text-white">
                                            {datosLider.nombre} {datosLider.apellidos}
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                
        
              <div className="rbt-counterup-area rbt-section-overlayping-top rbt-section-gapBottom pt--150 pb--75">
                
                <div className="section-title text-center mb--40 mt--60">
                    <p className="description has-medium-font-size mt--20 mb--0">En este espacio exclusivo podrás visualizar información relevante sobre el desempeño de usuarios de toda la reigíon en nuestro programa.</p>
                    <div className="rbt-button-group mt--30">
                        
                        <div class="btn-group" role="group">
                            <button type="button" class="rbt-btn btn-gradient dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            Regiones
                            </button>
                            <ul class="dropdown-menu special-dropdown">
                            <li><a class="dropdown-item" href="/superlider/México">México</a></li>
                            <li><a class="dropdown-item" href="/superlider/RoLA">Rola</a></li>
                            </ul>
                        </div>

                        <a className="rbt-btn btn-gradient hover-icon-reverse"  onClick={handleShow} >
                            <span className="icon-reverse-wrapper">
                                <span className="btn-text">Descargar reporte general</span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            </span>
                        </a>

                        <a className="rbt-btn hover-icon-reverse btn-border" href="/usuario/perfil">
                            <span className="icon-reverse-wrapper">
                                <span className="btn-text">Salir</span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            </span>
                        </a>
                    </div>
                    <Modal show={show} onHide={handleClose} style={{ borderRadius: '50px' }} centered size="lg">
                        <Modal.Header closeButton style={{ fontSize: '12px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                            <span style={{ fontSize: '22px', fontWeight: '700', color: '#000' }}>Reportes</span>
                        </Modal.Header>
                        <Modal.Body><Table style={{ width: '100%', textAlign: 'left', color: '#000',  }}>
                                <tbody style={{ border: 'none' }}>
                                    <tr>
                                        <td style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none', borderBottom:'none' }}><h5>Usuarios</h5><p>Este es el compilado donde podrás ver la cantidad de usuarios activos en el programa</p></td>
                                        <td style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none', borderBottom:'none' }}><h5>Reporte general</h5><p>Este es el compilado incluye el cálculo de todas las actividades de los usuarios. Por favor ten paciencia puede demorar un poco.</p></td>
                                    </tr>
                                    <tr>
                                        <td className="text-center" style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' , borderBottom:'none' }}>
                                                                        <a className="rbt-btn hover-icon-reverse btn-sm" href={`https://plsystem.quarkservers2.com/admin/usuarios_suscritos/reporte_region?id_temporada=${datosTemporada.id}&region=${regionServidor}&time=${Math.floor(Date.now()/1000)}`}  style={{ margin: '0 auto' }}>Descargar</a></td>
                                                                    
                                        <td className="text-center" style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' , borderBottom:'none' }}>
                                            <a className="rbt-btn hover-icon-reverse btn-sm" href={`https://plsystem.quarkservers2.com/admin/temporadas/reporte_excel/${datosTemporada.id}?region=${regionServidor}&distribuidor=0&time=${Math.floor(Date.now()/1000)}`}  style={{ background: '#f0b323', margin: '0 auto' }}>Descargar</a></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Modal.Body>
                    </Modal>
                </div>
               
                <div className="container">
                    <div className="row g-5">
                        <div className="section-title text-center">
                            <h2 className="title">Elige el distribuidor</h2>
                        </div>
                        {listaDistribuidores.map((distribuidor, index) => {
                            return(
                                <div className="col-lg-2 col-md-2 col-6 text-center">
                                    <div className="rbt-card variation-01 rbt-hover" style={{padding: '5px'}}>
                                    <a className="" href={`/superlider/resumen/${distribuidor.id}`}>
                                    <img src={`https://plsystem.quarkservers2.com/img/publicaciones/${distribuidor.imagen}`} className="img-fluid mb-3" alt="Card image" />
                                    <h5 className="rbt-card-title center">{distribuidor.nombre}</h5>
                                    </a>
                                    </div>
                                </div>
                            );
                        })}
                        
                    </div>
                </div>
              </div>
        
            </div>
          );
    }
  }
  export function ResumenSuper() {

    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    const { id_distribuidor } = useParams();
    // Cargo variables del servidor
    const [datosLider, setDatosLider] = useState(null);
    const [datosTemporada, setDatosTemporada] = useState(null);
    const [datosSuscripcion, setDatosSuscripcion] = useState(null);
    const [datosDistribuidor, setDatosDistribuidor] = useState(null);
    const [listadoUsuarios, setListadoUsuarios] = useState(null);
    const [usuariosTotales, setUsuariosTotales] = useState(null);
    const [usuariosActivos, setUsuariosActivos] = useState(null);
    const [usuariosParticipantes, setUsuariosParticipantes] = useState(null);
    const [usuariosInactivos, setUsuariosInactivos] = useState(null);
    const [totalSesiones, setTotalSesiones] = useState(null);
    const [totalTrivias, setTotalTrivias] = useState(null);
    const [totalJackpots, setTotalJackpots] = useState(null);
    const [pendientesSesiones, setPendientesSesiones] = useState(null);
    const [pendientesTrivias, setPendientesTrivias] = useState(null);
    const [pendientesJackpots, setPendientesJackpots] = useState(null);
    const [porcentajeActivos, setPorcentajeActivos] = useState(0);
    const [porcentajeParticipantes, setPorcentajeParticipantes] = useState(0);

    const [arrayNombres, setArrayNombres] = useState(null);
    const [arrayNombresSesiones, setArrayNombresSesiones] = useState(null);
    const [arrayNombresTrivias, setArrayNombresTrivias] = useState(null);
    const [topSesiones, setTopSesiones] = useState(null);
    const [topTrivias, setTopTrivias] = useState(null);
    const [topJackpots, setTopJackpots] = useState(null);
    const [numeroUsuariosSesiones, setNumeroUsuariosSesiones] = useState(null);
    const [numeroUsuariosTrivias, setNumeroUsuariosTrivias] = useState(null);
    const [numeroUsuariosJackpots, setNumeroUsuariosJackpots] = useState(null);
    const [top10, setTop10] = useState(null);
    const [participacionesSesiones, setParticipacionesSesiones] = useState(null);
    const [participacionesTrivias, setParticipacionesTrivias] = useState(null);
    const [participacionesJackpots, setParticipacionesJackpots] = useState(null);

    const porcentajeConDosDecimales = porcentajeActivos.toFixed(2);


    const[arrayFechas, setArrayFechas] = useState(null);
    const[arrayVisualizaciones, setArrayVisualizaciones] = useState(null);
    const[arrayEvaluaciones, setArrayEvaluaciones] = useState(null);
    const[arrayTrivias, setArrayTrivias] = useState(null);
    const[arrayJackpots, setArrayJackpots] = useState(null);

   

    

      // Termina el Auth redirect
    useEffect(() => {
    // llamada para los datos de la temporada
    const id_local = JSON.parse(localStorage.getItem('idUsuario'));
    axios.get('https://plsystem.quarkservers2.com/api/datos_basicos_super_lider', {
        params: {
            id_cuenta: cuentaPL,
            id_usuario: id_local,
            id_distribuidor: id_distribuidor,
        }
        })
        .then(function (response) {
        setDatosLider(response.data.usuario);
        setDatosSuscripcion(response.data.suscripcion);
        setDatosTemporada(response.data.temporada);
        setDatosDistribuidor(response.data.distribuidor);
        setListadoUsuarios(response.data.suscriptores);
        setUsuariosTotales(response.data.totales);
        setUsuariosActivos(response.data.activos);
        setUsuariosParticipantes(response.data.participantes);
        let usuarios_inactivos = parseInt(response.data.totales) - parseInt(response.data.activos);
        let porcentaje_activos = (parseInt(response.data.activos)*100)/parseInt(response.data.totales);
        let porcentaje_participantes = (parseInt(response.data.participantes)*100)/parseInt(response.data.totales);
        let porcentaje_activos_decimal = Math.round(porcentaje_activos * 100) / 100;
        let porcentaje_participantes_decimal = Math.round(porcentaje_participantes * 100) / 100;
        
        setUsuariosInactivos(usuarios_inactivos);
        
        setTotalSesiones(response.data.sesiones);
        setTotalTrivias(response.data.trivias);
        setTotalJackpots(response.data.jackpots);
        setPendientesSesiones(response.data.sesiones_pendientes);
        setPendientesTrivias(response.data.trivias_pendientes);
        setPendientesJackpots(response.data.jackpots_pendientes);
       
        setPorcentajeActivos(porcentaje_activos_decimal);
        setPorcentajeParticipantes(porcentaje_participantes_decimal);
        setTopSesiones(response.data.top_sesiones);
        setTopTrivias(response.data.top_trivias);
        setTopJackpots(response.data.top_jackpots);
        setTop10(response.data.top_10);
        setNumeroUsuariosSesiones(response.data.no_usuarios_sesiones);
        setNumeroUsuariosTrivias(response.data.no_usuarios_trivias);
        setNumeroUsuariosJackpots(response.data.no_usuarios_jackpots);
        setParticipacionesSesiones(response.data.participaciones_sesiones);
        setParticipacionesTrivias(response.data.participaciones_trivias);
        setParticipacionesJackpots(response.data.participaciones_jackpots);


        setArrayNombres(response.data.array_nombres);
        setArrayNombresSesiones(response.data.array_nombres_sesiones);
        setArrayNombresTrivias(response.data.array_nombres_trivias);

        setArrayFechas(response.data.array_fechas);
        setArrayVisualizaciones(response.data.engagement_visualizaciones);
        setArrayEvaluaciones(response.data.engagement_evaluaciones);
        setArrayTrivias(response.data.engagement_trivias);
        setArrayJackpots(response.data.engagement_jackpots);
        
        })
        .catch(function (error) {
        // handle error
        console.log('El error fue:'+error);
        });

    }, []);
    

    const obtenerFechaActual = () => {
        const fecha = new Date();
        const year = fecha.getFullYear();
        let month = fecha.getMonth() + 1;
        let day = fecha.getDate();

        // Ajustar el formato de mes y día para que tengan dos dígitos si es necesario
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }

        // Formatear la fecha en el formato YYYY-MM-DD requerido por el campo de fecha
        return `${year}-${month}-${day}`;
    };

    const obtenerFechaAtras = () => {
        const fecha = new Date();
        fecha.setDate(fecha.getDate() - 15); // Restar 7 días a la fecha actual
    
        const year = fecha.getFullYear();
        let month = fecha.getMonth() + 1;
        let day = fecha.getDate();
    
        // Ajustar el formato de mes y día para que tengan dos dígitos si es necesario
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }
    
        // Formatear la fecha en el formato YYYY-MM-DD requerido por el campo de fecha
        return `${year}-${month}-${day}`;
    };

    const actualizarPanel = (e) => {
        e.preventDefault();

        // llamada para los datos de la temporada
    const id_local = JSON.parse(localStorage.getItem('idUsuario'));
    axios.get('https://plsystem.quarkservers2.com/api/panel_super_lider', {
        params: {
            id_cuenta: cuentaPL,
            id_usuario: id_local,
            id_distribuidor: id_distribuidor,
            fecha_inicio: fechaDesde,
            fecha_final: fechaHasta
        }
        })
        .then(function (response) {
        console.log(response);
        setArrayFechas(response.data.fechas_array);
        setArrayVisualizaciones(response.data.engagement_visualizaciones);
        setArrayEvaluaciones(response.data.engagement_evaluaciones);
        setArrayTrivias(response.data.engagement_trivias);
        setArrayJackpots(response.data.engagement_jackpots);
        
        })
        .catch(function (error) {
        // handle error
        console.log('El error fue:'+error);
        });

    }

    // Establecer el estado inicial de los campos de fecha con la fecha actual
    const [fechaDesde, setFechaDesde] = useState(obtenerFechaAtras());
    const [fechaHasta, setFechaHasta] = useState(obtenerFechaActual());

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if(datosLider){
        return (
            <div>
                <div className="rbt-breadcrumb-default ptb--50 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-left">
                                    <h3 className="title">Panel Super Líder <span className="color-third">{datosDistribuidor.nombre}</span> </h3> <br/>
                                </div>
                                <div className="rbt-author-meta mb--10">
                                    <div className="rbt-avater">
                                        <a href="#"><img src={`https://plsystem.quarkservers2.com/img/usuarios/${datosLider.imagen}`} alt={datosLider.nombre}/></a>
                                    </div>
                                        <div className="rbt-author-info text-white">
                                            {datosLider.nombre} {datosLider.apellidos}
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                
        
              <div className="rbt-counterup-area rbt-section-overlayping-top rbt-section-gapBottom pt--150 pb--75">
                
                <div className="section-title text-center mb--40 mt--60">
                    <p className="description has-medium-font-size mt--20 mb--0">En este espacio exclusivo podrás visualizar información relevante sobre el desempeño de usuarios de tu compañía en nuestro programa.</p>
                    <div className="rbt-button-group mt--30">
                        <a className="rbt-btn btn-gradient hover-icon-reverse"  href="/superlider">
                            <span className="icon-reverse-wrapper">
                                <span className="btn-text">Cambiar Distribuidor</span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            </span>
                        </a>
                        <a className="rbt-btn btn-gradient hover-icon-reverse" href={`/superlider/usuarios/${datosDistribuidor.id}`}>
                            <span className="icon-reverse-wrapper">
                                <span className="btn-text">Administrar Usuarios</span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            </span>
                        </a>
                        <a className="rbt-btn hover-icon-reverse btn-border" href="/usuario/perfil">
                            <span className="icon-reverse-wrapper">
                                <span className="btn-text">Salir</span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            </span>
                        </a>
                    </div>
                </div>
               
                <div className="container">
                    <div className="row g-5">
                        <div className="section-title text-center">
                            <h2 className="title">Desempeño de usuarios</h2>
                            <h4 className="title" style={{color:'var(--color-body)'}}>Usuario: {usuariosTotales} Registrados (total de usuarios)</h4>
                        </div>
                        {/* Start Single Event  */}
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="rbt-card variation-01 rbt-hover">
                            <h5 className="rbt-card-title center">Usuarios activos e inactivos</h5>
                            <hr/>
                                <div className="rbt-card-img">
                                    <UsuariosActivos activos={usuariosActivos} no_activos={usuariosInactivos} />
                                    
                                </div>
                                <div className="rbt-card-body">
                                    <table style={{ width: '100%', textAlign: 'left', color: '#000', borderCollapse: 'collapse' }}>
                                        <tr>
                                            <td style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ display: 'block', width: '10px', height: '10px', margin: '10px', backgroundColor: '#36a2eb' }}></span>
                                                    <b style={{ margin: '5px' }}>{usuariosActivos}</b> Usuarios activos: ({porcentajeActivos.toFixed(2)}%)
                                                </div>
                                            </td>
                                            <td style={{ borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>Han iniciado sesión</td>
                                        </tr>
                                        <tr>
                                            <td style={{ verticalAlign: 'middle', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ display: 'block', width: '10px', height: '10px', margin: '10px', backgroundColor: '#ff6384' }}></span>
                                                    <b style={{ margin: '5px' }}>{usuariosInactivos}</b> Usuarios inactivos: ({(100 - porcentajeActivos).toFixed(2)}%)
                                                </div>
                                            </td>
                                            <td style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>Nunca han iniciado sesión</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* End Single Event  */}
                        {/* Start Single Event  */}
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="rbt-card variation-01 rbt-hover">
                            <h5 className="rbt-card-title center">Usuarios participantes</h5>
                            <hr/>
                                <div className="rbt-card-img">
                                <UsuariosParticipantes porcentaje={porcentajeParticipantes}/>
                                </div>
                                <div className="rbt-card-body">
                                <table style={{ width: '100%', textAlign: 'left', color: '#000', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ display: 'block', width: '10px', height: '10px', margin: '10px', backgroundColor: '#36a2eb' }}></span>
                                                    <b style={{ margin: '5px' }}>{usuariosParticipantes}</b> Usuarios ({porcentajeParticipantes.toFixed(2)}%)
                                                </div>
                                            </td>
                                            <td style={{ borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>Han participado en alguna actividad</td>
                                        </tr>
                                        <tr>
                                            <td style={{ verticalAlign: 'middle', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ display: 'block', width: '10px', height: '10px', margin: '10px', backgroundColor: '#ff6384' }}></span>
                                                    <b style={{ margin: '5px' }}>{usuariosTotales - usuariosParticipantes}</b> Usuarios ({(100 - porcentajeParticipantes).toFixed(2)}%)
                                                </div>
                                            </td>
                                            <td style={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' }}>No han participado en ninguna actividad</td>
                                        </tr>
                                    </tbody>
                                </table>

                                </div>
                            </div>
                        </div>
                        {/* End Single Event  */}
                    </div>
                </div>
              </div>
        
                <div className="rbt-service-area rbt-section-gap bg-color-extra2 pt--0 pb--0">
                    <div className="container">
                        {/*  Start Card Area */}
                        <div className="row row--15 mt_dec--30">
        
                            {/*  Start Service Grid  */}
                            <div className="col-lg-12 col-xl-12 col-xxl-12 col-md-6 col-sm-6 col-12 mt--30">
                                <div className="service-card service-card-6">
                                    <div className="inner pt--15 pb--15">
                                        <div className="row">
                                            <div className="col">
                                            <form className="row justify-content-end">
                                                <input type="hidden" name="id_distribuidor" value="" />
                                                <div className="col-4">
                                                    <div className="form-group d-flex">
                                                        <input
                                                            type="date"
                                                            name="fecha_desde"
                                                            className="form-control"
                                                            value={fechaDesde}
                                                            onChange={(e) => setFechaDesde(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group d-flex">
                                                        <input
                                                            type="date"
                                                            name="fecha_hasta"
                                                            className="form-control"
                                                            value={fechaHasta}
                                                            onChange={(e) => setFechaHasta(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            <div className="col-4">
                                                <a className="rbt-btn hover-icon-reverse btn-sm mr--20 ml--40" onClick={actualizarPanel} href="#" >Filtrar</a>
                                                <a className="rbt-btn hover-icon-reverse btn-sm" style={{ background: '#f0b323', cursor:'pointer' }} onClick={handleShow}>Descargar reportes</a>

                                                <Modal show={show} onHide={handleClose} style={{ borderRadius: '50px' }} centered size="lg">
                                                    <Modal.Header closeButton style={{ fontSize: '12px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                                                        <span style={{ fontSize: '22px', fontWeight: '700', color: '#000' }}>Reportes</span>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <p className="text-center" style={{ fontWeight: '700' }}>Selecciona una opción para descargar una hoja de cálculo con la información.</p>
                                                        <Table style={{ width: '100%', textAlign: 'left', color: '#000',  }}>
                                                            <tbody style={{ border: 'none' }}>
                                                                <tr>
                                                                    <td style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none', borderBottom:'none' }}><h5>Usuarios</h5><p>En este compilado podrás ver la lista completa de usuarios registrados en el programa.</p></td>
                                                                    <td style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none', borderBottom:'none' }}><h5>Puntos</h5><p>En este compilado podrás ver la participación en actividades y el puntaje total de los usuarios.</p></td>
                                                                    
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-center" style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' , borderBottom:'none' }}>
                                                                        <a className="rbt-btn hover-icon-reverse btn-sm" href={`https://plsystem.quarkservers2.com/admin/usuarios_suscritos/reporte?id_temporada=${datosTemporada.id}&id_distribuidor=${datosDistribuidor.id}`}  style={{ margin: '0 auto' }}>Descargar</a></td>
                                                                    <td className="text-center" style={{ verticalAlign: 'middle', borderBottom: '1px solid #ccc', borderLeft: 'none', borderRight: 'none' , borderBottom:'none' }}>
                                                                        <a className="rbt-btn hover-icon-reverse btn-sm" href={`https://plsystem.quarkservers2.com/admin/usuarios_suscritos/puntaje?id_temporada=${datosTemporada.id}&id_distribuidor=${datosDistribuidor.id}`}  style={{ background: '#f0b323', margin: '0 auto' }}>Descargar</a></td>
                                                                        
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </Modal.Body>
                                                </Modal>
                                            </div>
                                        </form>
                                            </div>
                                        </div>
                                        <div className="alert alert-warning text-center" ><p style={{fontSize:'12px', marginBottom:'5px'}}><b>Algunas fechas de lanzamiento podrían no aparecer en las acotaciones de la gráfica. </b>
                                         Para visualizar las fechas de los lanzamientos con mayor detalle, seleccione un periodo no mayor a 30 días.</p>
                                        </div>
                                        
                                        <PanelLider 
                                            fechas={arrayFechas}
                                            visualizaciones={arrayVisualizaciones}
                                            evaluaciones={arrayEvaluaciones}
                                            trivias={arrayTrivias}
                                            jackpots={arrayJackpots} />
                                        
        
                                        <div className="rbt-service-area rbt-section-gap pb--60 pt--60 ">
                                            <div className="container">
                                                <div className="row mb--60">
                                                    <div className="col-lg-12">
                                                        <div className="section-title text-center">
                                                            <h2 className="title">Actividades Completadas</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Start Card Area */}
                                                <div className="row row--15 mt_dec--30">
                                                    {/* Start Single Card  */}
                                                    <div className="col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                                                        <div className="rbt-card text-center">
                                                            <img src="/assets/images/micrositio/PLe_Liderico01_2024.png" alt="card-icon" />
                                                            <h5 className="title"><a href="#">Sesiones</a></h5>
                                                            <table style={{ borderCollapse: 'collapse', textAlign: 'left' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Sesiones</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{totalSesiones - pendientesSesiones}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Usuarios</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{numeroUsuariosSesiones}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Participaciones</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{participacionesSesiones}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>


                                                        </div>
                                                    </div>
                                                    {/* End Single Card  */}
        
                                                    {/* Start Single Card  */}
                                                    <div className="col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                                                        <div className="rbt-card text-center">
                                                            <img src="/assets/images/micrositio/PLe_Liderico02_2024.png" alt="card-icon" />
                                                            <h5 className="title"><a href="#">Trivias</a></h5>
                                                            <table style={{ borderCollapse: 'collapse', textAlign: 'left' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Trivias</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{totalTrivias - pendientesTrivias}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Usuarios</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{numeroUsuariosTrivias}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Participaciones</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{participacionesTrivias}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                    {/* End Single Card  */}
        
                                                    {/* Start Single Card  */}
                                                    <div className="col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                                                        <div className="rbt-card text-center">
                                                            <img src="/assets/images/micrositio/PLe_Liderico03_2024.png" alt="card-icon" />
                                                            <h5 className="title"><a href="#">Jackpots</a></h5>
                                                            <table style={{ borderCollapse: 'collapse', textAlign: 'left' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Jackpots</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{totalJackpots - pendientesJackpots}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Usuarios</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{numeroUsuariosJackpots}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Ganadores</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{participacionesJackpots}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                    {/* End Single Card  */}
        
                                                    {/* Start Single Card  */}
                                                    <div className="col-xl-3 col-md-6 col-sm-6 col-12 mt--30">
                                                    <div className="rbt-card text-center">
                                                        <img src="/assets/images/micrositio/PLe_Liderico04_2024.png" alt="card-icon" />
                                                            <h5 className="title"><a href="#">Engagement</a></h5>
                                                            <table style={{ borderCollapse: 'collapse', textAlign: 'left' }}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Jackpots</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{totalJackpots - pendientesJackpots}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Usuarios</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{numeroUsuariosJackpots}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ border: 'none' }}>Ganadores</td>
                                                                        <td style={{ border: 'none', fontWeight:'bold' }}>{participacionesJackpots}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>
                                                    </div>
                                                    {/* End Single Card  */}
        
                                                </div>
                                                {/* End Card Area */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*  End Service Grid  */}
        
                        </div>
                        {/*  End Card Area */}
                    </div>
                </div>
        
                <div className="rbt-advance-tab-area rbt-section-gap pt--30">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="title">Top 10</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="advance-tab-button">
                                    <ul className="nav nav-tabs tab-button-style-2 mb--0" id="myTab-4" role="tablist">
                                        <li role="presentation">
                                            <a href="#" className="tab-button active" id="home-tab-4" data-bs-toggle="tab" data-bs-target="#home-4" role="tab" aria-controls="home" aria-selected="true">
                                                <span className="title">Top sesiones</span>
                                            </a>
                                        </li>
                                        <li role="presentation">
                                            <a href="#" className="tab-button" id="profile-tab-4" data-bs-toggle="tab" data-bs-target="#profile-4" role="tab" aria-controls="profile-4" aria-selected="false">
                                                <span className="title">Top trivias</span>
                                            </a>
                                        </li>
                                        <li role="presentation">
                                            <a href="#" className="tab-button" id="contact-tab-4" data-bs-toggle="tab" data-bs-target="#contact-4" role="tab" aria-controls="contact-4" aria-selected="false">
                                                <span className="title">Top 10 y Puntaje</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-10 offset-lg-1 mt--10">
                                <div className="tab-content advance-tab-content-style-2 pl--0 pr--0">
                                    <div className="tab-pane fade active show" id="home-4" role="tabpanel" aria-labelledby="home-tab-4">
                                        <div className="content">
                                            <div className="col-lg-12 col-md-4 col-12">
                                                <div className="rbt-card variation-01 rbt-hover">
                                                <hr/>
                                                    <div className="rbt-card-img">
                                                        <div className="table-responsive mobile-table-750">
                                                            <table className="rbt-table table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th>LUGAR</th>
                                                                        <th>SESION</th>
                                                                        <th>PARTICIPANTES</th>
                                                                    </tr>
                                                                </thead>
                                                                
                                                                <tbody>
                                                                    {topSesiones.map((sesion, index) => (
                                                                        
                                                                        <tr  key={sesion.id}>
                                                                            <th>{index+1}</th>
                                                                            <td>{arrayNombresSesiones[sesion.id]}</td>
                                                                            <td>{sesion.puntos}</td>
                                                                        </tr>
                                                                    
                                                                    ))}
                                                                </tbody>
                                                                
                                                                
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="profile-4" role="tabpanel" aria-labelledby="profile-tab-4">
                                        <div className="content">
                                            <div className="col-lg-12 col-md-4 col-12">
                                                <div className="rbt-card variation-01 rbt-hover">
                                                <hr/>
                                                    <div className="rbt-card-img">
                                                        <div className="table-responsive mobile-table-750">
                                                            <table className="rbt-table table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th>LUGAR</th>
                                                                        <th>TRIVIA</th>
                                                                        <th>PARTICIPANTES</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {topTrivias.map((trivia, index) => (
                                                                        
                                                                        <tr  key={trivia.id}>
                                                                            <th>{index+1}</th>
                                                                            <td>{arrayNombresTrivias[trivia.id]}</td>
                                                                            <td>{trivia.puntos}</td>
                                                                        </tr>
                                                                    
                                                                    ))}
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="contact-4" role="tabpanel" aria-labelledby="contact-tab-4">
                                        <div className="content">
                                            <div className="col-lg-12 col-md-4 col-12">
                                                <div className="rbt-card variation-01 rbt-hover">
                                                <hr/>
                                                    <div className="rbt-card-img">
                                                        <div className="table-responsive mobile-table-750">
                                                            <table className="rbt-table table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th>LUGAR</th>
                                                                        <th>NOMBRE</th>
                                                                        <th>COMPAÑIA</th>
                                                                        <th>PUNTOS</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {top10.slice(0, 10).map((usuario, index) => (
                                                                        
                                                                    <tr  key={usuario.id}>
                                                                        <th>{index+1}</th>
                                                                        <td>{arrayNombres[usuario.id]}</td>
                                                                        <td>{datosDistribuidor.nombre}</td>
                                                                        <td>{usuario.puntos}</td>
                                                                    </tr>
                                                                    
                                                                ))}
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
    }
  }
export function Top10Lider() {
    return (
      <div>
        <h2>Top 10</h2>
      </div>
    );
  }
export function UsuariosLider() {
    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    // Cargo variables del servidor
    const [recargarDatos, setRecargarDatos] = useState(false);
    const [datosLider, setDatosLider] = useState(null);
    const [datosTemporada, setDatosTemporada] = useState(null);
    const [datosSuscripcion, setDatosSuscripcion] = useState(null);
    const [datosDistribuidor, setDatosDistribuidor] = useState(null);
    const [listadoUsuarios, setListadoUsuarios] = useState(null);
    const [usuariosTotales, setUsuariosTotales] = useState(null);
    const [usuariosActivos, setUsuariosActivos] = useState(null);
    const [usuariosParticipantes, setUsuariosParticipantes] = useState(null);
    const [usuariosInactivos, setUsuariosInactivos] = useState(null);
    const [totalSesiones, setTotalSesiones] = useState(null);
    const [totalTrivias, setTotalTrivias] = useState(null);
    const [totalJackpots, setTotalJackpots] = useState(null);
    const [pendientesSesiones, setPendientesSesiones] = useState(null);
    const [pendientesTrivias, setPendientesTrivias] = useState(null);
    const [pendientesJackpots, setPendientesJackpots] = useState(null);
    const [porcentajeActivos, setPorcentajeActivos] = useState(0);

    const [arrayNombre, setArrayNombres] = useState(null);
    const [topSesiones, setTopSesiones] = useState(null);
    const [topTrivias, setTopTrivias] = useState(null);
    const [top10, setTop10] = useState(null);
    const [agregandoUsuario, setAgregandoUsuario] = useState(false);

    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        correo: '',
        categoria: ''
      });
    const [errors, setErrors] = useState({
        nombre: '',
        apellido: '',
        correo: '',
        categoria: ''
    });

    const [usuarioActual, setUsuarioActual] = useState({});

      
      // Termina el Auth redirect
    useEffect(() => {
    // llamada para los datos de la temporada
    const id_local = JSON.parse(localStorage.getItem('idUsuario'));
    axios.get('https://plsystem.quarkservers2.com/api/datos_basicos_lider', {
        params: {
            id_cuenta: cuentaPL,
            id_usuario: id_local
        }
        })
        .then(function (response) {
        //console.log(response);
        setDatosLider(response.data.usuario);
        setDatosSuscripcion(response.data.suscripcion);
        setDatosTemporada(response.data.temporada);
        setDatosDistribuidor(response.data.distribuidor);
        setListadoUsuarios(response.data.suscriptores);
        setUsuariosTotales(response.data.totales);
        setUsuariosActivos(response.data.activos);
        setUsuariosParticipantes(response.data.participantes);
        let usuarios_inactivos = parseInt(response.data.totales) - parseInt(response.data.activos);
        setUsuariosInactivos(usuarios_inactivos);
        
        setTotalSesiones(response.data.sesiones);
        setTotalTrivias(response.data.trivias);
        setTotalJackpots(response.data.jackpots);
        setPendientesSesiones(response.data.sesiones_pendientes);
        setPendientesTrivias(response.data.trivias_pendientes);
        setPendientesJackpots(response.data.jackpots_pendientes);
        let porcentaje_activos = (parseInt(response.data.activos)*100)/parseInt(response.data.totales);
        setPorcentajeActivos(porcentaje_activos);
        setTopSesiones(response.data.top_sesiones);
        setTopTrivias(response.data.top_trivias);
        setTop10(response.data.top_10);
        setArrayNombres(response.data.array_nombres);
        
        })
        .catch(function (error) {
        // handle error
        console.log('El error fue:'+error);
        });

    }, [recargarDatos]);

    const handleChangeAgregar = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });

        if (name === 'nombre' || name === 'apellido') {
            if (value.length < 3) {
                setErrors({
                ...errors,
                [name]: 'El campo debe tener al menos 3 caracteres'
                });
            } else {
                setErrors({
                ...errors,
                [name]: ''
                });
            }
        }
        // Validar correo electrónico
        if (name === 'correo') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const forbiddenDomains = ['gmail.com', 'hotmail.com'];
            if (!emailRegex.test(value)) {
            setErrors({
                ...errors,
                [name]: 'El correo electrónico no es válido'
            });
            } else {
            const domain = value.split('@')[1];
                if (forbiddenDomains.includes(domain)) {
                    setErrors({
                    ...errors,
                    [name]: 'No se permiten correos personales como Gmail ni Hotmail'
                    });
                } else {
                    setErrors({
                    ...errors,
                    [name]: ''
                    });
                }
            }
        }
      };

      const handleSubmitAgregar = (e) => {
        e.preventDefault();
        // Aquí puedes llamar a una función para manejar los datos, por ejemplo:
        handleAgregarUsuario(formData);
      };

      const handleAgregarUsuario = async (datos) => {

        // VOY A EVITAR QUE PUEDAN AGREGAR AL USUARIO
        if(datos.correo!='lcvelazquez@hermos.com.mx'){
            //console.log(datos);
            setAgregandoUsuario(true);
            try {
            const response = await axios.post('https://plsystem.quarkservers2.com/api/agregar_usuario', {
                id_cuenta: cuentaPL,
                id_temporada: datosTemporada.id,
                id_distribuidor: datosDistribuidor.id,
                nombre: datos.nombre,
                apellidos: datos.apellido,
                correo: datos.correo,
            });
            //console.log(datos);
            setAgregandoUsuario(false);
            setFormData({
                nombre: '',
                apellido: '',
                correo: '',
                categoria: ''
            });
            setRecargarDatos(!recargarDatos);
            Swal.fire({
                title: '¡Proceso exitoso!',
                text: 'El usuario se ha sumado a su base. Puede visualizarlo ya en la base de su lista de usuarios.',
                icon: 'success',
                confirmButtonColor: '#213746',
                confirmButtonText: 'Aceptar'
                });
            
            } catch (error) {
            console.error('Error al enviar la solicitud:', error);
            }
        }else{
            Swal.fire({
                title: '¡Advertencia!',
                text: 'El usuario se había eliminado con anterioridad',
                icon: 'warning',
                confirmButtonColor: '#FF0000',
                confirmButtonText: 'Aceptar'
                });
        }

        
      };

        const handleRestaurarContraseña = () => {
            Swal.fire({
            title: '¿Está seguro que quiere restaurar la contraseña de este usuario?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#213746',
            cancelButtonColor: '#FF0000',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
            }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                title: '¡Contraseña restaurada!',
                icon: 'success',
                confirmButtonColor: '#213746',
                confirmButtonText: 'Aceptar'
                });
            }
            });
        };

        const handleEliminarUsuario = (id_suscripcion) => {
            Swal.fire({
                title: '¿Está seguro que quiere eliminar a este usuario?',
                text: 'Esta opción no se puede deshacer.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#213746',
                cancelButtonColor: '#FF0000',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // Aquí puedes hacer lo que necesites con el id_suscripcion, por ejemplo, enviarlo al backend para eliminar el usuario
                    //console.log(id_suscripcion);
                    try {
                        const response = await axios.post('https://plsystem.quarkservers2.com/api/eliminar_usuario', {
                            id_cuenta: cuentaPL,
                            id_temporada: datosTemporada.id,
                            id_distribuidor: datosDistribuidor.id,
                            id_suscripcion: id_suscripcion
                        });

                        Swal.fire({
                            title: '¡Usuario eliminado!',
                            icon: 'success',
                            confirmButtonColor: '#213746',
                            confirmButtonText: 'Aceptar'
                        });
                        
                        setRecargarDatos(!recargarDatos);
                        
                        } catch (error) {
                        console.error('Error al enviar la solicitud:', error);
                        }

                    
                }
            });
        };

        const [showModal, setShowModal] = useState(false);

        const handleOpenModal = (usuario) => {
            setShowModal(true);
            setUsuarioActual(usuario);
          };

        const handleCloseModal = () => {
            setShowModal(false);
            setUsuarioActual({});
          };

        const handleActualizarPerfil = () => {
        // Aquí puedes llamar a tu función handleSubmitActualizar
        // y pasarle los datos del usuario almacenados en usuarioActual
        handleSubmitActualizar(usuarioActual);
        handleCloseModal();
        };

        const handleChangeInput = (e) => {
            const { id, value } = e.target;
            setUsuarioActual((prevUsuario) => ({
              ...prevUsuario,
              [id]: value,
            }));
          };

          async function handleSubmitActualizar(usuarioActual) {
            // Aquí puedes realizar cualquier lógica necesaria para enviar los datos actualizados del usuario al servidor
            console.log('Datos actualizados del usuario:', usuarioActual);

            try {
                const response = await axios.post('https://plsystem.quarkservers2.com/api/actualizar_usuario', {
                  id_cuenta: cuentaPL,
                  id_temporada: datosTemporada.id,
                  id_distribuidor: datosDistribuidor.id,
                  nombre: usuarioActual.nombre,
                  apellidos: usuarioActual.apellidos,
                  correo: usuarioActual.email,
                  suscripcion: usuarioActual.suscripcion,
                });
                setRecargarDatos(!recargarDatos);
                
              } catch (error) {
                console.error('Error al enviar la solicitud:', error);
              }
          }

    if(listadoUsuarios){
        //console.log(listadoUsuarios)
        return (
            <div>
                <div className="rbt-breadcrumb-default ptb--50 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-left">
                                    <h3 className="title">Panel Líder <span className="color-third">{datosDistribuidor.nombre}</span> </h3> <br/>
                                </div>
                                <div className="rbt-author-meta mb--10">
                                    <div className="rbt-avater">
                                        <a href="#"><img src={`https://plsystem.quarkservers2.com/img/usuarios/${datosLider.imagen}`} alt={datosLider.nombre}/></a>
                                    </div>
                                        <div className="rbt-author-info text-white">
                                            {datosLider.nombre} {datosLider.apellidos}
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div className="rbt-counterup-area rbt-section-overlayping-top rbt-section-gapBottom" style={{marginTop:'-50px'}}>

                <div className="section-title text-center mb--0 mt--60">
                    <div className="section-title text-center">
                        <h2 className="title">Gestión de usuarios</h2>
                    </div>
                    <div className="rbt-button-group mt--30">
                        <a className="rbt-btn btn-gradient hover-icon-reverse" href="/lider">
                            <span className="icon-reverse-wrapper">
                                <span className="btn-text">Panel Líder</span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            </span>
                        </a>
                        <a className="rbt-btn hover-icon-reverse btn-border" href="/usuario/perfil">
                            <span className="icon-reverse-wrapper">
                                <span className="btn-text">Salir</span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                            </span>
                        </a>
                    </div>
                </div>

                    <div className="container">
                        <div className="row row--30 gy-5">
    
                            {/* Tabla de usuarios  */}
                            <div className="col-lg-8 col-xl-9 order-1 order-lg-1">
                                <div className="row g-5">
                                    {/* Start Single Event  */}
                                    <div className="col-xl-12 col-lg-6 col-md-6 col-12">
                                        <div className="rbt-card event-grid-card variation-01 rbt-hover">
                                            <div className="rbt-card-body" style={{ maxHeight: '532px', overflowY: 'scroll'}}>
                                                <div className="table-responsive mobile-table-750">
                                                    <table className="rbt-table table table-borderless">
                                                        <thead>
                                                            <tr>
                                                                <th>EDICIÓN</th>
                                                                <th>NOMBRE</th>
                                                                <th>CORREO</th>
                                                                <th>CONTRASEÑA</th>
                                                                <th>EDITAR</th>
                                                                <th>ELIMINAR</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {listadoUsuarios &&
                                                            Object.values(listadoUsuarios).map((usuario, index) => (
                                                            <tr key={index}>
                                                                <th>{index + 1}</th>
                                                                <td>{usuario.nombre} {usuario.apellidos}</td>
                                                                <td>{usuario.email}</td>
                                                                <td>
                                                                <a className="rbt-btn btn-xs bg-color-warning-opacity radius-round color-white" href="#" title="Restaurar" onClick={handleRestaurarContraseña}>
                                                                    <i className="feather-rotate-ccw"></i> Restaurar
                                                                </a>
                                                                </td>
                                                                <td>
                                                                <Button className="rbt-btn btn-xs bg-color-info-opacity radius-round color-info" onClick={() => handleOpenModal(usuario)}>
                                                                    <i className="feather-edit pl--0"></i> Editar
                                                                </Button>
                                                                <Modal className="popup-usuario" backdrop="static" centered show={showModal} onHide={handleCloseModal} >
                                                                    <Modal.Body>
                                                                    <div className="section-title">
                                                                        <h4 className="rbt-title-style-3">Editar Perfil</h4>
                                                                    </div>
                                                                    <div className="rainbow-tab-content tab-content">
                                                                        <div className="tab-pane fade active show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                                        <form action="#" className="rbt-profile-row rbt-default-form row row--15">
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="rbt-form-group">
                                                                                <label htmlFor="firstname">Nombre(s)</label>
                                                                                <input id="nombre" type="text" value={usuarioActual.nombre} onChange={handleChangeInput} />
                                                                            </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="rbt-form-group">
                                                                                <label htmlFor="lastname">Apellidos(s)</label>
                                                                                <input id="apellidos" type="text" value={usuarioActual.apellidos} onChange={handleChangeInput} />
                                                                            </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="rbt-form-group">
                                                                                <label htmlFor="username">Correo</label>
                                                                                <input id="email" type="email" value={usuarioActual.email} onChange={handleChangeInput} />
                                                                            </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="form-group">
                                                                                <label htmlFor="lastname" style={{marginBottom:'1px'}}>Categoría </label>
                                                                                <select required style={{height:'53px'}}>
                                                                                <option value="" disabled>Seleccione una opción</option>
                                                                                <option>Especialista</option>
                                                                                <option>Vendedor</option>
                                                                                </select>
                                                                            </div>
                                                                            </div>
                                                                        </form>
                                                                        </div>
                                                                    </div>
                                                                    </Modal.Body>
                                                                    <Modal.Footer className="justify-content-between">
                                                                    <Button variant="secondary" className="rbt-btn btn-sm" onClick={handleActualizarPerfil}>
                                                                        Actualizar
                                                                    </Button>
                                                                    <Button variant="secondary" className="rbt-btn btn-sm" onClick={handleCloseModal}>
                                                                        Cerrar
                                                                    </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                                </td>
                                                                <td>
                                                                <a className="rbt-btn btn-xs bg-color-danger-opacity radius-round color-danger" href="#" title="Delete" onClick={() => handleEliminarUsuario(usuario.suscripcion)}>
                                                                    <i className="feather-trash-2 pl--0"></i> Eliminar
                                                                </a>

                                                                </td>
                                                            </tr>
                                                            ))}
                                                            
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Event  */}
                                </div>
                            </div>
    
                            {/* Agregar un usuario  */}
                            <div className="col-lg-4 col-xl-3 order-2 order-lg-2">
                                <aside className="rbt-sidebar-widget-wrapper">
                                    <div className="rbt-single-widget rbt-widget-categories ">
                                        <div className="inner">
                                            <h4 className="rbt-widget-title">Agregar usuario</h4>
                                            <form id="contact-form" onSubmit={handleSubmitAgregar} className="rbt-profile-row rbt-default-form row row--15">
                                                <div className="form-group">
                                                    <label>Nombre(s)</label>
                                                    <input name="nombre" value={formData.nombre} onChange={handleChangeAgregar} type="text" required style={{marginBottom:'0px'}}/>
                                                    
                                                </div>
                                                {errors.nombre && <span className="text-danger">{errors.nombre}</span>}
                                                <div className="form-group">
                                                    <label>Apellido(s)</label>
                                                    <input name="apellido" value={formData.apellido} onChange={handleChangeAgregar} type="text" style={{marginBottom:'0px'}} required/>
                                                </div>
                                                {errors.apellido && <span className="text-danger">{errors.apellido}</span>}
                                                <div className="form-group">
                                                    <label>Correo</label>
                                                    <input name="correo" value={formData.correo} onChange={handleChangeAgregar} type="email" style={{marginBottom:'0px'}} required/>
                                                </div>
                                                {errors.correo && <span className="text-danger">{errors.correo}</span>}
                                                <div className="form-group mb--25">
                                                    <label style={{color:'#3e3e3e'}}>Categoría </label>
                                                    <select name="categoria" value={formData.categoria} onChange={handleChangeAgregar} required>
                                                    <option value="" disabled>Seleccione una opción</option>
                                                    <option value='ventas'>Vendedor</option>
                                                    <option value='especialista'>Especialista</option>
                                                    
                                                    </select>
                                                </div>
                                                <div className="form-submit-group">
                                                    <button name="submit" type="submit" id="submit" className="rbt-btn btn-md btn-gradient hover-icon-reverse w-100" disabled={agregandoUsuario}>
                                                    <span className="icon-reverse-wrapper">
                                                        <span className="btn-text">{agregandoUsuario ? 'Agregando...' : 'Agregar'}</span>
                                                        <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                        <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                    </span>
                                                    </button>
                                                </div>
                                                </form>
                                        </div>
                                    </div>
                                    {/* End Widget Area  */}
                                </aside>
                            </div>
    
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    }
export function UsuariosSuperLider() {
        const {cuentaPL, setCuentaPL} = useVariablesGlobales();
        const { id_distribuidor } = useParams();
        // Cargo variables del servidor
        const [recargarDatos, setRecargarDatos] = useState(false);
        const [datosLider, setDatosLider] = useState(null);
        const [datosTemporada, setDatosTemporada] = useState(null);
        const [datosSuscripcion, setDatosSuscripcion] = useState(null);
        const [datosDistribuidor, setDatosDistribuidor] = useState(null);
        const [listadoUsuarios, setListadoUsuarios] = useState(null);
        const [usuariosTotales, setUsuariosTotales] = useState(null);
        const [usuariosActivos, setUsuariosActivos] = useState(null);
        const [usuariosParticipantes, setUsuariosParticipantes] = useState(null);
        const [usuariosInactivos, setUsuariosInactivos] = useState(null);
        const [totalSesiones, setTotalSesiones] = useState(null);
        const [totalTrivias, setTotalTrivias] = useState(null);
        const [totalJackpots, setTotalJackpots] = useState(null);
        const [pendientesSesiones, setPendientesSesiones] = useState(null);
        const [pendientesTrivias, setPendientesTrivias] = useState(null);
        const [pendientesJackpots, setPendientesJackpots] = useState(null);
        const [porcentajeActivos, setPorcentajeActivos] = useState(0);
    
        const [arrayNombre, setArrayNombres] = useState(null);
        const [topSesiones, setTopSesiones] = useState(null);
        const [topTrivias, setTopTrivias] = useState(null);
        const [top10, setTop10] = useState(null);
        const [agregandoUsuario, setAgregandoUsuario] = useState(false);
    
        const [formData, setFormData] = useState({
            nombre: '',
            apellido: '',
            correo: '',
            categoria: ''
          });
        const [errors, setErrors] = useState({
            nombre: '',
            apellido: '',
            correo: '',
            categoria: ''
        });
    
        const [usuarioActual, setUsuarioActual] = useState({});
    
          
          // Termina el Auth redirect
        useEffect(() => {
        // llamada para los datos de la temporada
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        axios.get('https://plsystem.quarkservers2.com/api/datos_basicos_super_lider', {
            params: {
                id_cuenta: cuentaPL,
                id_usuario: id_local,
                id_distribuidor: id_distribuidor
            }
            })
            .then(function (response) {
            //console.log(response);
            setDatosLider(response.data.usuario);
            setDatosSuscripcion(response.data.suscripcion);
            setDatosTemporada(response.data.temporada);
            setDatosDistribuidor(response.data.distribuidor);
            setListadoUsuarios(response.data.suscriptores);
            setUsuariosTotales(response.data.totales);
            setUsuariosActivos(response.data.activos);
            setUsuariosParticipantes(response.data.participantes);
            let usuarios_inactivos = parseInt(response.data.totales) - parseInt(response.data.activos);
            setUsuariosInactivos(usuarios_inactivos);
            
            setTotalSesiones(response.data.sesiones);
            setTotalTrivias(response.data.trivias);
            setTotalJackpots(response.data.jackpots);
            setPendientesSesiones(response.data.sesiones_pendientes);
            setPendientesTrivias(response.data.trivias_pendientes);
            setPendientesJackpots(response.data.jackpots_pendientes);
            let porcentaje_activos = (parseInt(response.data.activos)*100)/parseInt(response.data.totales);
            setPorcentajeActivos(porcentaje_activos);
            setTopSesiones(response.data.top_sesiones);
            setTopTrivias(response.data.top_trivias);
            setTop10(response.data.top_10);
            setArrayNombres(response.data.array_nombres);
            
            })
            .catch(function (error) {
            // handle error
            console.log('El error fue:'+error);
            });
    
        }, [recargarDatos]);
    
        const handleChangeAgregar = (e) => {
            const { name, value } = e.target;
            setFormData({
              ...formData,
              [name]: value
            });
    
            if (name === 'nombre' || name === 'apellido') {
                if (value.length < 3) {
                    setErrors({
                    ...errors,
                    [name]: 'El campo debe tener al menos 3 caracteres'
                    });
                } else {
                    setErrors({
                    ...errors,
                    [name]: ''
                    });
                }
            }
            // Validar correo electrónico
            if (name === 'correo') {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const forbiddenDomains = ['gmail.com', 'hotmail.com'];
                if (!emailRegex.test(value)) {
                setErrors({
                    ...errors,
                    [name]: 'El correo electrónico no es válido'
                });
                } else {
                const domain = value.split('@')[1];
                    if (forbiddenDomains.includes(domain)) {
                        setErrors({
                        ...errors,
                        [name]: 'No se permiten correos personales como Gmail ni Hotmail'
                        });
                    } else {
                        setErrors({
                        ...errors,
                        [name]: ''
                        });
                    }
                }
            }
          };
    
          const handleSubmitAgregar = (e) => {
            e.preventDefault();
            // Aquí puedes llamar a una función para manejar los datos, por ejemplo:
            handleAgregarUsuario(formData);
          };
    
          const handleAgregarUsuario = async (datos) => {
    
            //console.log(datos);
            setAgregandoUsuario(true);
            try {
            const response = await axios.post('https://plsystem.quarkservers2.com/api/agregar_usuario', {
                id_cuenta: cuentaPL,
                id_temporada: datosTemporada.id,
                id_distribuidor: datosDistribuidor.id,
                nombre: datos.nombre,
                apellidos: datos.apellido,
                correo: datos.correo,
            });
            //console.log(datos);
            setAgregandoUsuario(false);
            setFormData({
                nombre: '',
                apellido: '',
                correo: '',
                categoria: ''
            });
            setRecargarDatos(!recargarDatos);
            Swal.fire({
                title: '¡Proceso exitoso!',
                text: 'El usuario se ha sumado a su base. Puede visualizarlo ya en la base de su lista de usuarios.',
                icon: 'success',
                confirmButtonColor: '#213746',
                confirmButtonText: 'Aceptar'
                });
            
            } catch (error) {
            console.error('Error al enviar la solicitud:', error);
            }
          };
    
        
            /*
            const handleAgregarUsuario = (event) => {
                event.preventDefault();
                Swal.fire({
                title: '¿Está seguro que quiere añadir un nuevo usuario?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#213746',
                cancelButtonColor: '#FF0000',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar'
                }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                    title: 'Nuevo usuario agregado a la base de -Compañía-',
                    icon: 'success',
                    confirmButtonColor: '#213746',
                    confirmButtonText: 'Aceptar'
                    });
                }
                });
            };
            */
    
            const handleRestaurarContraseña = () => {
                Swal.fire({
                title: '¿Está seguro que quiere restaurar la contraseña de este usuario?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#213746',
                cancelButtonColor: '#FF0000',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar'
                }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                    title: '¡Contraseña restaurada!',
                    icon: 'success',
                    confirmButtonColor: '#213746',
                    confirmButtonText: 'Aceptar'
                    });
                }
                });
            };
    
            const handleEliminarUsuario = (id_suscripcion) => {
                Swal.fire({
                    title: '¿Está seguro que quiere eliminar a este usuario?',
                    text: 'Esta opción no se puede deshacer.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#213746',
                    cancelButtonColor: '#FF0000',
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        // Aquí puedes hacer lo que necesites con el id_suscripcion, por ejemplo, enviarlo al backend para eliminar el usuario
                        //console.log(id_suscripcion);
                        try {
                            const response = await axios.post('https://plsystem.quarkservers2.com/api/eliminar_usuario', {
                                id_cuenta: cuentaPL,
                                id_temporada: datosTemporada.id,
                                id_distribuidor: datosDistribuidor.id,
                                id_suscripcion: id_suscripcion
                            });
    
                            Swal.fire({
                                title: '¡Usuario eliminado!',
                                icon: 'success',
                                confirmButtonColor: '#213746',
                                confirmButtonText: 'Aceptar'
                            });
                            
                            setRecargarDatos(!recargarDatos);
                            
                            } catch (error) {
                            console.error('Error al enviar la solicitud:', error);
                            }
    
                        
                    }
                });
            };
    
            const [showModal, setShowModal] = useState(false);
    
            const handleOpenModal = (usuario) => {
                setShowModal(true);
                setUsuarioActual(usuario);
              };
    
            const handleCloseModal = () => {
                setShowModal(false);
                setUsuarioActual({});
              };
    
            const handleActualizarPerfil = () => {
            // Aquí puedes llamar a tu función handleSubmitActualizar
            // y pasarle los datos del usuario almacenados en usuarioActual
            handleSubmitActualizar(usuarioActual);
            handleCloseModal();
            };
    
            const handleChangeInput = (e) => {
                const { id, value } = e.target;
                setUsuarioActual((prevUsuario) => ({
                  ...prevUsuario,
                  [id]: value,
                }));
              };
    
              async function handleSubmitActualizar(usuarioActual) {
                // Aquí puedes realizar cualquier lógica necesaria para enviar los datos actualizados del usuario al servidor
                console.log('Datos actualizados del usuario:', usuarioActual);
    
                try {
                    const response = await axios.post('https://plsystem.quarkservers2.com/api/actualizar_usuario', {
                      id_cuenta: cuentaPL,
                      id_temporada: datosTemporada.id,
                      id_distribuidor: datosDistribuidor.id,
                      nombre: usuarioActual.nombre,
                      apellidos: usuarioActual.apellidos,
                      correo: usuarioActual.email,
                      suscripcion: usuarioActual.suscripcion,
                    });
                    setRecargarDatos(!recargarDatos);
                    
                  } catch (error) {
                    console.error('Error al enviar la solicitud:', error);
                  }
              }
    
        if(listadoUsuarios){
            //console.log(listadoUsuarios)
            return (
                <div>
                    <div className="rbt-breadcrumb-default ptb--50 ptb_md--50 ptb_sm--30 bg-gradient-1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="breadcrumb-inner text-left">
                                        <h3 className="title">Panel Super Líder <span className="color-third">{datosDistribuidor.nombre}</span> </h3> <br/>
                                    </div>
                                    <div className="rbt-author-meta mb--10">
                                        <div className="rbt-avater">
                                            <a href="#"><img src={`https://plsystem.quarkservers2.com/img/usuarios/${datosLider.imagen}`} alt={datosLider.nombre}/></a>
                                        </div>
                                            <div className="rbt-author-info text-white">
                                                {datosLider.nombre} {datosLider.apellidos}
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div className="rbt-counterup-area rbt-section-overlayping-top rbt-section-gapBottom" style={{marginTop:'-50px'}}>
    
                    <div className="section-title text-center mb--0 mt--60">
                        <div className="section-title text-center">
                            <h2 className="title">Gestión de usuarios</h2>
                        </div>
                        <div className="rbt-button-group mt--30">
                            <a className="rbt-btn hover-icon-reverse btn-border" href="/superlider">
                                <span className="icon-reverse-wrapper">
                                    <span className="btn-text">Seleccionar Distribuidor</span>
                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                </span>
                            </a>
                            <a className="rbt-btn btn-gradient hover-icon-reverse" href={`/superlider/${datosDistribuidor.id}`}>
                                <span className="icon-reverse-wrapper">
                                    <span className="btn-text">Panel Super Líder</span>
                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                </span>
                            </a>
                            <a className="rbt-btn hover-icon-reverse btn-border" href="/usuario/perfil">
                                <span className="icon-reverse-wrapper">
                                    <span className="btn-text">Salir</span>
                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                </span>
                            </a>
                        </div>
                    </div>
    
                        <div className="container">
                            <div className="row row--30 gy-5">
        
                                {/* Tabla de usuarios  */}
                                <div className="col-lg-8 col-xl-9 order-1 order-lg-1">
                                    <div className="row g-5">
                                        {/* Start Single Event  */}
                                        <div className="col-xl-12 col-lg-6 col-md-6 col-12">
                                            <div className="rbt-card event-grid-card variation-01 rbt-hover">
                                                <div className="rbt-card-body" style={{ maxHeight: '532px', overflowY: 'scroll'}}>
                                                    <div className="table-responsive mobile-table-750">
                                                        <table className="rbt-table table table-borderless">
                                                            <thead>
                                                                <tr>
                                                                    <th>EDICIÓN</th>
                                                                    <th>NOMBRE</th>
                                                                    <th>CORREO</th>
                                                                    <th>CONTRASEÑA</th>
                                                                    <th>EDITAR</th>
                                                                    <th>ELIMINAR</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {listadoUsuarios &&
                                                                Object.values(listadoUsuarios).map((usuario, index) => (
                                                                <tr key={index}>
                                                                    <th>{index + 1}</th>
                                                                    <td>{usuario.nombre} {usuario.apellidos}</td>
                                                                    <td>{usuario.email}</td>
                                                                    <td>
                                                                    <a className="rbt-btn btn-xs bg-color-warning-opacity radius-round color-white" href="#" title="Restaurar" onClick={handleRestaurarContraseña}>
                                                                        <i className="feather-rotate-ccw"></i> Restaurar
                                                                    </a>
                                                                    </td>
                                                                    <td>
                                                                    <Button className="rbt-btn btn-xs bg-color-info-opacity radius-round color-info" onClick={() => handleOpenModal(usuario)}>
                                                                        <i className="feather-edit pl--0"></i> Editar
                                                                    </Button>
                                                                    <Modal className="popup-usuario" backdrop="static" centered show={showModal} onHide={handleCloseModal} >
                                                                        <Modal.Body>
                                                                        <div className="section-title">
                                                                            <h4 className="rbt-title-style-3">Editar Perfil</h4>
                                                                        </div>
                                                                        <div className="rainbow-tab-content tab-content">
                                                                            <div className="tab-pane fade active show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                                            <form action="#" className="rbt-profile-row rbt-default-form row row--15">
                                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                                <div className="rbt-form-group">
                                                                                    <label htmlFor="firstname">Nombre(s)</label>
                                                                                    <input id="nombre" type="text" value={usuarioActual.nombre} onChange={handleChangeInput} />
                                                                                </div>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                                <div className="rbt-form-group">
                                                                                    <label htmlFor="lastname">Apellidos(s)</label>
                                                                                    <input id="apellidos" type="text" value={usuarioActual.apellidos} onChange={handleChangeInput} />
                                                                                </div>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                                <div className="rbt-form-group">
                                                                                    <label htmlFor="username">Correo</label>
                                                                                    <input id="email" type="email" value={usuarioActual.email} onChange={handleChangeInput} />
                                                                                </div>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="lastname" style={{marginBottom:'1px'}}>Categoría </label>
                                                                                    <select required style={{height:'53px'}}>
                                                                                    <option value="" disabled>Seleccione una opción</option>
                                                                                    <option>Especialista</option>
                                                                                    <option>Vendedor</option>
                                                                                    </select>
                                                                                </div>
                                                                                </div>
                                                                            </form>
                                                                            </div>
                                                                        </div>
                                                                        </Modal.Body>
                                                                        <Modal.Footer className="justify-content-between">
                                                                        <Button variant="secondary" className="rbt-btn btn-sm" onClick={handleActualizarPerfil}>
                                                                            Actualizar
                                                                        </Button>
                                                                        <Button variant="secondary" className="rbt-btn btn-sm" onClick={handleCloseModal}>
                                                                            Cerrar
                                                                        </Button>
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                    </td>
                                                                    <td>
                                                                    <a className="rbt-btn btn-xs bg-color-danger-opacity radius-round color-danger" href="#" title="Delete" onClick={() => handleEliminarUsuario(usuario.suscripcion)}>
                                                                        <i className="feather-trash-2 pl--0"></i> Eliminar
                                                                    </a>
    
                                                                    </td>
                                                                </tr>
                                                                ))}
                                                                
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Event  */}
                                    </div>
                                </div>
        
                                {/* Agregar un usuario  */}
                                <div className="col-lg-4 col-xl-3 order-2 order-lg-2">
                                    <aside className="rbt-sidebar-widget-wrapper">
                                        <div className="rbt-single-widget rbt-widget-categories ">
                                            <div className="inner">
                                                <h4 className="rbt-widget-title">Agregar usuario</h4>
                                                <form id="contact-form" onSubmit={handleSubmitAgregar} className="rbt-profile-row rbt-default-form row row--15">
                                                    <div className="form-group">
                                                        <label>Nombre(s)</label>
                                                        <input name="nombre" value={formData.nombre} onChange={handleChangeAgregar} type="text" required style={{marginBottom:'0px'}}/>
                                                        
                                                    </div>
                                                    {errors.nombre && <span className="text-danger">{errors.nombre}</span>}
                                                    <div className="form-group">
                                                        <label>Apellido(s)</label>
                                                        <input name="apellido" value={formData.apellido} onChange={handleChangeAgregar} type="text" style={{marginBottom:'0px'}} required/>
                                                    </div>
                                                    {errors.apellido && <span className="text-danger">{errors.apellido}</span>}
                                                    <div className="form-group">
                                                        <label>Correo</label>
                                                        <input name="correo" value={formData.correo} onChange={handleChangeAgregar} type="email" style={{marginBottom:'0px'}} required/>
                                                    </div>
                                                    {errors.correo && <span className="text-danger">{errors.correo}</span>}
                                                    <div className="form-group mb--25">
                                                        <label style={{color:'#3e3e3e'}}>Categoría </label>
                                                        <select name="categoria" value={formData.categoria} onChange={handleChangeAgregar} required>
                                                        <option value="" disabled>Seleccione una opción</option>
                                                        <option value='ventas'>Vendedor</option>
                                                        <option value='especialista'>Especialista</option>
                                                        
                                                        </select>
                                                    </div>
                                                    <div className="form-submit-group">
                                                        <button name="submit" type="submit" id="submit" className="rbt-btn btn-md btn-gradient hover-icon-reverse w-100" disabled={agregandoUsuario}>
                                                        <span className="icon-reverse-wrapper">
                                                            <span className="btn-text">{agregandoUsuario ? 'Agregando...' : 'Agregar'}</span>
                                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                        </span>
                                                        </button>
                                                    </div>
                                                    </form>
                                            </div>
                                        </div>
                                        {/* End Widget Area  */}
                                    </aside>
                                </div>
        
                                
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
export function UsuarioLider() {
    return (
    <div>
        <h2>Usuario Lider</h2>
    </div>
    );
}
export function SolicitudesLogros() {
    return (
    <div>
        <h2>Logros</h2>
    </div>
    );
}

export default Resumen;