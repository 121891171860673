import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);


function generarDatosAleatorios() {
    return Array.from({ length: 60 }, () => Math.floor(Math.random() * 11)); // Ajuste para incluir el 0
}


// Datos aleatorios para cada categoría
const iniciosSesion = generarDatosAleatorios();
const sesiones = generarDatosAleatorios();
const trivias = generarDatosAleatorios();
const jackpots = generarDatosAleatorios();
const engagement = generarDatosAleatorios();

const midata = {
    labels: ["01-02", "02-02", "03-02", "04-02", "05-02", "06-02", "07-02", "08-02", "09-02", "10-02", "11-02", "12-02", "13-02", "14-02", "15-02", "16-02", "17-02", "18-02", "19-02", "20-02", "21-02", "22-02", "23-02", "24-02", "25-02", "26-02", "27-02", "28-02", "01-03", "02-03", "03-03", "04-03", "05-03", "06-03", "07-03", "08-03", "09-03", "10-03", "11-03", "12-03", "13-03", "14-03", "15-03", "16-03", "17-03", "18-03", "19-03", "20-03", "21-03", "22-03", "23-03", "24-03", "25-03", "26-03", "27-03", "28-03", "29-03", "30-03", "31-03"],
    datasets: [
        {
            label: 'Inicios de sesión',
            data: iniciosSesion,
            tension: 0,
            borderColor: '#6a7eff',
            pointRadius: 5,
            pointBorderColor: '#6a7eff',
            pointBackgroundColor: '#6a7eff',
        },
        {
            label: 'Sesiones',
            data: sesiones,
            tension: 0,
            borderColor: '#064a76',
            pointRadius: 5,
            pointBorderColor: '#064a76',
            pointBackgroundColor: '#064a76',
        },
        {
            label: 'Trivias',
            data: trivias,
            tension: 0,
            borderColor: '#d9534f',
            pointRadius: 5,
            pointBorderColor: '#d9534f',
            pointBackgroundColor: '#d9534f',
        },
        {
            label: 'Jackpots',
            data: jackpots,
            tension: 0,
            borderColor: '#f0b323',
            pointRadius: 5,
            pointBorderColor: '#f0b323',
            pointBackgroundColor: '#f0b323',
        },
        {
            label: 'Engagement',
            data: engagement,
            tension: 0,
            borderColor: '#00b2b5',
            pointRadius: 5,
            pointBorderColor: '#00b2b5',
            pointBackgroundColor: '#00b2b5',
        },
    ],
};

const misoptions = {
    scales: {
        y: {
            min: 0
        },
        x: {
            ticks: {
                color: 'rgb(0, 0, 0)'
            }
        }
    }
};


export default function LinesChart() {
    return <Line data={midata} options={misoptions}/>
}