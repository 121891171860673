import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        position: 'bottom',
        boxWidth: 10,
    },
};

const dataActivos = {
    labels: ['Activos', 'No activos'],
    datasets: [
        {
            label: 'Usuarios',
            data: [3, 0],
            backgroundColor: [
                'rgba(54, 162, 235)',
                'rgba(255, 99, 132)',
            ],
            borderColor: [
                'rgba(255, 255, 255)',
                'rgba(255, 255, 255)',
            ],
            borderWidth: 1,
        },
    ],
};

const dataParticipantes = {
    labels: ['Activos', 'No activos'],
    datasets: [
        {
            label: 'Usuarios',
            data: [2, 1],
            backgroundColor: [
                'rgba(54, 162, 235)',
                'rgba(255, 99, 132)',
            ],
            borderColor: [
                'rgba(255, 255, 255)',
                'rgba(255, 255, 255)',
            ],
            borderWidth: 1,
        },
    ],
};

 const dataPuntosPerfil = {
     labels: ['Sesiones', 'Evaluaciones', 'Trivias', 'Jackpot', 'Extra'],
     datasets: [
       {
         data: [5000, 2500, 0, 300, 30000],
         backgroundColor: [
           '#FF6384',
           '#36A2EB',
           '#FFCE56',
           '#4BC0C0',
           '#9966FF'
         ],
         hoverBackgroundColor: [
           '#FF6384',
           '#36A2EB',
           '#FFCE56',
           '#4BC0C0',
           '#9966FF'
         ]
       }
     ]
   };
   
 export function PuntosPerfil() {
     return <Pie data={dataPuntosPerfil} options={options} />;
 }

export function Activos() {
    return <Pie data={dataActivos} options={options} />;
}

export function Participantes() {
    return <Pie data={dataParticipantes} options={options} />;
}


