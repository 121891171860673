import {React, useState, useEffect} from 'react';
import * as echarts from 'echarts';
import axios from 'axios';

export function DatosPerfil() {
  const [puntajeUsuario, setPuntajeUsuario] = useState(null);
  useEffect(() => {
    const id_local = JSON.parse(localStorage.getItem('idUsuario'));
    axios.get('https://plsystem.quarkservers2.com/api/puntaje_usuario', {
        params: {
          id_usuario: id_local,
          id_temporada: 1
        }
      })
      .then(function (response) {
        setPuntajeUsuario(response.data);
        console.log('Resultado:'+response.data);
      })
      .catch(function (error) {
        // handle error
        //console.log('El error fue:'+error);
      });
  },[]);
  useEffect(() => {
    if(puntajeUsuario){
      const chartContainer = document.getElementById('echarts-pie-chart');
      const chart = echarts.init(chartContainer);

      const data = [
        { value: puntajeUsuario.visualizaciones, name: 'Sesiones' },
        { value: puntajeUsuario.evaluaciones, name: 'Evaluaciones' },
        { value: puntajeUsuario.trivia, name: 'Trivias' },
        { value: puntajeUsuario.jackpots, name: 'Jackpots' },
        { value: puntajeUsuario.extra, name: 'Extra' }
      ];

      const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        color: ['#0a78c2', '#3b627d', '#f7a1a1', '#f6d079', '#e5e6e8'], // Personalizar colores aquí
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['60%', '100%'],
            center: ['50%', '70%'],
            // adjust the start and end angle
            startAngle: 180,
            endAngle: 360,
            data: data
          }
        ]
      };

      chart.setOption(option);

      return () => {
        chart.dispose();
      };
    }
    
  }, [puntajeUsuario]);

  return <div id="echarts-pie-chart" style={{ width: '100%', height: '400px' }} />;
};

export function UsuariosParticipantes({porcentaje}) {
  useEffect(() => {
    const chartContainer = document.getElementById('usuarios-participantes-chart');
    const chart = echarts.init(chartContainer);
    const restante = 100 - parseFloat(porcentaje);

    // Redondear el porcentaje a un decimal
    const porcentajeRedondeado = parseFloat(porcentaje).toFixed(1);
    const restanteRedondeado = parseFloat(restante).toFixed(1);

    const data = [
        { value: porcentajeRedondeado, name: 'Participantes' },
        { value: restanteRedondeado, name: 'No participantes' }
    ];

    const option = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '90%',
            left: 'center',
            formatter: function(name) {
                // Obtener el índice del dato correspondiente al nombre
                const dataIndex = data.findIndex(item => item.name === name);
                // Obtener el valor del dato correspondiente
                const value = data[dataIndex].value;
                return `${name}: ${value}%`; // Formato de la leyenda: nombre: valor%
            }
        },
        color: ['#36a2eb', '#ff6384'], // Personalizar colores aquí
        series: [
            {
                name: 'Usuarios',
                type: 'pie',
                radius: ['80%', '60%'],
                avoidLabelOverlap: false,
                label: {
                    show: false // Oculta las etiquetas en el centro del gráfico
                },
                labelLine: {
                    show: false
                },
                data: data
            }
        ]
    };
  
      chart.setOption(option);
  
      return () => {
        chart.dispose();
      };
    }, []);
  
    return <div id="usuarios-participantes-chart" style={{ width: '100%', height: '200px' }} />;
  };

export function UsuariosActivos({activos, no_activos}) {
  useEffect(() => {
    const chartContainer = document.getElementById('usuarios-activos-chart');
    const chart = echarts.init(chartContainer);

    const total = parseFloat(activos)+parseFloat(no_activos);
    const porcentajeActivos = (parseFloat(activos)*100)/total;
    const porcentajeRestante = 100-porcentajeActivos;

    const data = [
        { value: porcentajeActivos.toFixed(1), name: 'Activos' },
        { value: porcentajeRestante.toFixed(1), name: 'No activos' }
    ];

    const option = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '90%',
            left: 'center',
            formatter: function(name) {
              // Obtener el índice del dato correspondiente al nombre
              const dataIndex = data.findIndex(item => item.name === name);
              // Obtener el valor del dato correspondiente
              const value = data[dataIndex].value;
              return `${name}: ${value}%`; // Formato de la leyenda: nombre: valor%
          }
        },
        color: ['#36a2eb', '#ff6384'], // Personalizar colores aquí

        series: [
            {
                name: 'Usuarios',
                type: 'pie',
                radius: ['80%', '60%'],
                avoidLabelOverlap: false,
                label: {
                    show: false
                },
                labelLine: {
                    show: false
                },
                data: data
            }
        ]
    };
    
        chart.setOption(option);
    
        return () => {
            chart.dispose();
        };
        }, []);
    
        return <div id="usuarios-activos-chart" style={{ width: '100%', height: '200px' }} />;
};

export function PanelLider({fechas, visualizaciones, evaluaciones, trivias, jackpots}) {
    useEffect(() => {
      const chartContainer = document.getElementById('panel-lider-chart');
      const chart = echarts.init(chartContainer);
      
      console.log(fechas);
      const xAxisData = fechas;
      const data1 = visualizaciones; // Inicios de sesión
      const data2 = evaluaciones; // Sesiones
      const data3 = trivias; // Trivias
      const data4 = jackpots; // Jackpots
      //const data5 = [350, 332, 301, 334, 390, 330, 320, 320, 302, 301, 354, 390, 330, 320, 330]; // Engagement
  
      const option = {
        title: {
          text: 'Grafica de Resultados'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Sesiones', 'Evaluaciones', 'Trivias', 'Jackpots']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Sesiones',
            type: 'line',
            step: 'start',
            data: data1,
            color: '#f1a07e'
          },
          {
            name: 'Evaluaciones',
            type: 'line',
            step: 'middle',
            data: data2,
            color: '#213746'
          },
          {
            name: 'Trivias',
            type: 'line',
            step: 'end',
            data: data3,
            color: '#f0b323'
          },
          {
            name: 'Jackpots',
            type: 'line',
            step: 'start',
            data: data4,
            color: '#064a76'
          }
          /*
          ,{
            name: 'Engagement',
            type: 'line',
            step: 'middle',
            data: data5,
            color: '#e5e6e8'
          }
          */
        ]
      };
  
      chart.setOption(option);
  
      return () => {
        chart.dispose();
      };
    }, [fechas]);
  
    return <div id="panel-lider-chart" style={{ width: '100%', height: '400px' }} />;
  };

export default DatosPerfil;
